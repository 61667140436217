import { Field } from '@/components/data-model/erd/types';
import { camelCaseToUnderScope } from '@/utils/util';
import Schema from 'async-validator';
export const camelCase = /^[a-z]+([A-Z][a-z]+)*$/;
const MAX_LEN_OF_NAME = 128;
const rules = {
  name: [
    {
      required: true,
      message: '属性名不能为空',
    },
    {
      max: MAX_LEN_OF_NAME,
      message: '属性名不超过128个字符',
    },
    {
      pattern: /^[a-z]+([A-Z][a-z]+)*$/,
      message: '属性名称填写错误，必须是小驼峰格式',
    },
  ],
  description: [
    {
      required: true,
      message: '属性描述不能为空',
    },
  ],
  dbTypeName: [
    {
      required: true,
      message: '数据类型不能为空！',
    },
  ],
  columnName: [
    {
      required: true,
      message: '数据库名称不能为空',
    },
    {
      max: 64,
      message: '数据库名称不超过64个字符',
    },
    {
      pattern: /^[a-zA-Z]\w*/,
      message: '只允许西文字母（大小写均可）、数字、下划线，且不能以数字开头',
    },
  ],
};

const validateFields = async (fields: Field[]) => {
  const validators = fields
    .filter((e) => !e.isSystem)
    .map((item) => {
      const validator = new Schema(rules);
      return validator.validate(item);
    });
  const res = await Promise.allSettled(validators);
  const faild: any = res.find((item) => item.status === 'rejected');

  if (faild) {
    throw faild.reason;
  }
};

// 根据数据类型校验默认值的格式;
const enum DATA_TYPES {
  INTEGER = 'Integer',
  LONG = 'Long',
  FLOAT = 'Float',
  DOUBLE = 'Double',
  BOOLEAN = 'Boolean',
  STRING = 'String',
  JAVA_UTIL_DATE = 'java.util.Date',
  BIG_DECIMAL = 'java.math.BigDecimal',
}
const DATA_TYPES_REGEXP_TPL = {
  [DATA_TYPES.STRING]: '[\\u4e00-\\u9fa5|a-zA-Z0-9]{1,$length$}',
  [DATA_TYPES.INTEGER]: '(\\-|\\+)?\\d+',
  [DATA_TYPES.BOOLEAN]: '0|1',
  [DATA_TYPES.LONG]: '(\\-|\\+)?\\d+',
  [DATA_TYPES.DOUBLE]: '\\d{1,$length$}(.\\d{1,$precision$})?',
  [DATA_TYPES.BIG_DECIMAL]: '\\d{1,$length$}(.\\d{1,$precision$})?',
  [DATA_TYPES.FLOAT]: '\\d{1,$length$}(.\\d{1,$precision$})?',
  [DATA_TYPES.JAVA_UTIL_DATE]:
    '([1-2][0-9][0-9][0-9]-[0-1]{0,1}[0-9]-[0-3]{0,1}[0-9])\\s(20|21|22|23|[0-1]\\d):[0-5]\\d:[0-5]\\d',
};

const genDataTypeRegexp = (type: DATA_TYPES, values: any) =>
  new RegExp(`^${DATA_TYPES_REGEXP_TPL[type]}$`.replace(/\$(\w+)\$/g, (a, b: string) => values[b]));

const validRowDefaultValue = (row: any, typeRows: any[]) => {
  // 判断是否为自定义输入
  if (row.defaultValueType !== 1) {
    return true;
  }
  const { defaultValue } = row;
  const { type, length, precision } = typeRows.find((item) => item.id === Number(row.typeId));
  const regexp = genDataTypeRegexp(type, { length: length - (precision || 0), precision });
  if (!regexp.test(defaultValue)) {
    return false;
  }
  // 默认值范围校验
  const time = new Date(defaultValue);
  const startTime = new Date('1970-01-01 00:00:01');
  const endTime = new Date('2038-01-09 03:14:07');
  const msg = '当前默认值超出该类型所支持的范围';
  switch (type) {
    case 'Long':
      if (defaultValue >= BigInt(-9223372036854775808) && defaultValue <= BigInt(9223372036854775807)) {
        return true;
      }
      return msg;
    case 'Integer':
      if (defaultValue >= BigInt(-2147483648) && defaultValue <= BigInt(2147483647)) {
        return true;
      }
      return msg;
    case 'java.util.date':
      if (time >= startTime && time <= endTime) {
        return true;
      }
      return msg;
  }
};

export const validRowsDefaultValue = (rows: any, typeRows: any) => {
  for (let i = 0, len = rows.length; i < len; i++) {
    const res = validRowDefaultValue(rows[i], typeRows);
    if (res === false) {
      throw new Error(`字段${rows[i].columnName}默认值未通过其对应数据类型的校验`);
    }
    if (typeof res === 'string') {
      throw new Error(res);
    }
  }
};

export default function useEditModel() {
  const onInputName = (field: Field, value: string) => {
    const name = value.trim();
    if (field.columnName.length === 0 || camelCaseToUnderScope(field.name) === field.columnName) {
      // eslint-disable-next-line no-param-reassign
      field.columnName = camelCaseToUnderScope(name);
    }
    // eslint-disable-next-line no-param-reassign
    field.name = name;
  };
  return {
    validateFields,
    onInputName,
  };
}
