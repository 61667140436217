import { ref, Ref } from 'vue';
import _ from 'lodash/fp';
import { getDivLine } from './util';

interface Column {
  name: string;
  description: string;
  type: string;
  notNull: boolean;
  unique: boolean;
  segment: boolean;
  pinyin: boolean;
  relateTable?: number;
  relateColumn?: number;
}
export interface Table {
  id?: number;
  name: string;
  description: string;
  owner: number;
  tag: number;
  classification: number;
  fields: Array<Column>;
  position: {
    x: number;
    y: number;
    temp?: boolean;
  };
  dragging?: number;
  selected?: boolean;
  _shiftX?: number; // 鼠标相对于table left的距离
  _shiftY?: number; // 鼠标位置相对于table top的距离
  showMore?: boolean;
}
interface Line {
  startMarker?: {
    x: number;
    y: number;
  };
  endMarker?: {
    x: number;
    y: number;
  };
  middleMarker?: {
    x: number;
    y: number;
  };
  path: string;
  selected?: boolean;
  type?: number | string;
}
type Relation = Array<number>;
export const tables: Ref<Array<Table>> = ref([]);
export const relations: Ref<Array<Relation>> = ref([]);
export const relationLines: Ref<Array<Line>> = ref([]);
export const viewWidth = ref(0);
export const viewHeight = ref(0);
export const svgOffset = ref({
  x: 0,
  y: 0,
});

const getPos = (index: number) => [tables.value[index].position.x, tables.value[index].position.y];
const getTableHeight = (index: number) => {
  if (tables.value[index].showMore || tables.value[index].fields.length < 6) {
    return tables.value[index].fields.length * 30 + 48;
  }
  return 6 * 30 + 48;
};

const getLinePosition = (
  startPos: Array<number>,
  endPos: Array<number>,
  startIndex: number,
  endIndex: number,
  type: number | string,
) => {
  if (startIndex === endIndex) {
    return {
      startMarker: {
        x: startPos[0] + 15,
        y: startPos[1] - 18,
      },
      endMarker: {
        x: startPos[0] - 20,
        y: startPos[1] + 15,
      },
      middleMarker: {
        x: startPos[0] + 210,
        y: startPos[1] + 10,
      },
      path: `M ${startPos[0] + 20} ${startPos[1]} A 25 25 0 1 0 ${startPos[0]} ${startPos[1] + 20}`,
      type: 1,
    };
  }
  const line = getDivLine(
    [...startPos, startPos[0] + 200, startPos[1] + getTableHeight(startIndex)],
    [...endPos, endPos[0] + 200, endPos[1] + getTableHeight(endIndex)],
  );
  const dist = Math.sqrt((line[1].x - line[0].x) ** 2 + (line[1].y - line[0].y) ** 2);
  const dirX = (line[1].x - line[0].x) / dist;
  const dirY = (line[1].y - line[0].y) / dist;
  const showLine = line[0]?.x && line[1]?.x;
  return {
    startMarker: {
      x: showLine ? dirX * 30 + line[0].x : -1000,
      y: showLine ? dirY * 30 + line[0].y : -1000,
    },
    endMarker: {
      x: showLine ? dirX * -40 + line[1].x : -1000,
      y: showLine ? dirY * -40 + line[1].y : -1000,
    },
    middleMarker: {
      x: showLine ? (line[0].x + line[1].x) / 2 : -1000,
      y: showLine ? (line[0].y + line[1].y) / 2 : -1000,
    },
    path: line[0]?.x && line[1]?.x ? `M ${line[0]?.x} ${line[0]?.y} L ${line[1]?.x} ${line[1]?.y}` : '',
    type,
  };
};
export const getLines = () =>
  _.map(([start, end, type]) => {
    const startPos = getPos(start);
    const endPos = getPos(end);
    return getLinePosition(startPos, endPos, start, end, type);
  })(relations.value);

export const recalcCanvasSize = () => {
  const maxX = Math.max(..._.map('position.x')(tables.value), 600);
  viewWidth.value = maxX + 220;
  const maxY = Math.max(
    ..._.map((table: Table) => table.position.y + table.fields.length * 30 + 48)(tables.value),
    600,
  );
  viewHeight.value = maxY + 20;
};
export const move = (index: number, ev: MouseEvent): void => {
  // const xPos = tables.value[index].position.x + movementX;
  // const yPos = tables.value[index].position.y + movementY;
  const currentTable = tables.value[index];
  console.log({ ev });

  currentTable.position.x = ev.pageX - (currentTable?._shiftX ?? 0);
  currentTable.position.y = ev.pageY - (currentTable?._shiftY ?? 0);

  tables.value[index].position.temp = false;
  recalcCanvasSize();
  relations.value.forEach((relation, rIndex) => {
    if (relation.includes(index)) {
      const start = tables.value[relation[0]].position;
      const end = tables.value[relation[1]].position;
      const startPos = [start.x, start.y];
      const endPos = [end.x, end.y];
      relationLines.value[rIndex] = getLinePosition(startPos, endPos, relation[0], relation[1], relation[2]);
    }
  });
};

export const clearSelected = () => {
  [...tables.value, ...relationLines.value].forEach((item: any) => {
    // eslint-disable-next-line no-param-reassign
    item.selected = false;
  });
};

export const drawingRelation = ref(false);
export const tempRelation = ref(-1);
export const newRelation = ref([-1, -1]);
export const newRelationLine = ref([
  [-1, -1],
  [-1, -1],
]);
export const drawRelationStart = (ev: MouseEvent, index: number) => {
  drawingRelation.value = true;
  newRelationLine.value[0] = [ev.clientX - svgOffset.value.x, ev.clientY - svgOffset.value.y];
  newRelation.value[0] = index;
};
export const tempLinePath = ref('');
const drawTempLine = (startIndex: number, endIndex: number) => {
  newRelationLine.value[1] = [-1, -1];
  const tempLinePosition = getLinePosition(getPos(startIndex), getPos(endIndex), startIndex, endIndex, 1);
  tempLinePath.value = tempLinePosition.path;
};
export const draw = (ev: MouseEvent) => {
  if (!~tempRelation.value) {
    newRelationLine.value[1] = [ev.clientX - svgOffset.value.x, ev.clientY - svgOffset.value.y];
  } else {
    drawTempLine(newRelation.value[0], tempRelation.value);
  }
};
export const clearNewRelation = () => {
  drawingRelation.value = false;
  newRelationLine.value = [
    [-1, -1],
    [-1, -1],
  ];
  newRelation.value = [-1, -1];
};
const checkRelation = () => {
  const isDrawing = drawingRelation.value;
  const newRelationString = JSON.stringify(newRelation.value);
  const reverseRelationString = JSON.stringify([newRelation.value[1], newRelation.value[0]]);
  const relationsString = JSON.stringify(relations.value);
  const duplicate = ~relationsString.indexOf(newRelationString) || ~relationsString.indexOf(reverseRelationString);
  return isDrawing && !duplicate;
};
export const drawRelationEnd = (index: number) => {
  newRelation.value[1] = index;
  if (checkRelation()) {
    relations.value.push([...newRelation.value]);
    const newRelationTableId = _.map((r: number) => tables.value[r])(newRelation.value);
    clearNewRelation();
    return newRelationTableId;
  }
  clearNewRelation();
  return null;
};

export const drawTempRleation = (index: number, isSelfModel?: true) => {
  if (drawingRelation.value && isSelfModel) {
    tempRelation.value = index;
  }
};

export const removeTempRleation = (isSelfModel?: true) => {
  if (isSelfModel) {
    tempRelation.value = -1;
  }
};
