import {
  stopServiceData,
  checkBeforeStart,
  releaseServiceData,
} from '@/views/service-management/business-service/utils/service-detail-data';
import { ref } from 'vue';
import { ElMessageBox } from 'element-plus';
import { openDomainDialog } from '@/views/service-management/business-service/components/domainConfig/dataCenter';
import { DeploymentMethod } from '@/views/conf-management/types';

export default function (options: any) {
  const { tenant, domainRef } = options;
  const buttons = ref([] as any);
  // 初始化
  const initialize = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const name = buttons.value.init.label;
    ElMessageBox.confirm(`确定${name}此服务, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      buttons.value.init.disabled = true;
      try {
        await options.applyChange();
        options.getLog();
      } catch (error) {
        console.log(error);
      } finally {
        buttons.value.init.disabled = false;
      }
    });
  };

  // 启动
  const start = async () => {
    const { code, data } = (await checkBeforeStart()) as any;
    const message = code === 0 && data === 'ok' ? '请确认此服务已初始化, 是否继续?' : data;
    ElMessageBox.confirm(message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      if (tenant.value?.deploymentMethod === DeploymentMethod.VitrualMachine) {
        return domainRef.value?.handleStartWithoutDomainInfo();
      }
      openDomainDialog();
    });
  };

  // 停止
  const stop = () => {
    ElMessageBox.confirm(`请确认停止此服务, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => stopServiceData());
  };

  // 日志
  const log = () => {
    console.log('日志');
    options.getLog();
  };

  // 发版
  const release = () => {
    console.log('发版');
    releaseServiceData();
  };

  buttons.value = {
    init: {
      label: '初始化',
      type: 'primary',
      disabled: false,
      eventOption: {
        click: () => initialize(),
      },
    },
    start: {
      label: '启动',
      disabled: false,
      eventOption: {
        click: () => start(),
      },
    },
    stop: {
      label: '停止',
      disabled: false,
      eventOption: {
        click: () => stop(),
      },
    },
    release: {
      label: '发版',
      disabled: false,
      eventOption: {
        click: () => release(),
      },
    },
    log: {
      label: '日志',
      disabled: false,
      eventOption: {
        click: () => log(),
      },
    },
  };

  return {
    buttons,
  };
}
