
import { ref, watch } from 'vue';
import useClassifications from '../utils/service-baseinfo-classification';
import useTags from '../utils/service-baseinfo-tag';
import { updateModel } from '@/api/schema/model';
import OwnerSelect from '@/components/owners-select/Index.vue';
import { alloverEdit } from '@/layout/messageCenter/routerRef';

export default {
  name: 'ModelBaseInfo',
  components: {
    OwnerSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    tags: {
      type: Array,
      default: () => [],
    },
    classifications: {
      type: Array,
      default: () => [],
    },
  },
  setup(props: { data: any; tags: any[]; classifications: any[] }) {
    // 是否为显示模式标识，默认为true
    const isShowMode = ref(true);

    // 表单数据
    const formData = ref({} as any);

    watch(
      () => props.data,
      (newValue) => {
        formData.value = newValue;
      },
      { immediate: true },
    );

    // 负责人名称
    const ownersName = ref('');

    // 初始化负责人名称
    formData.value.owners.forEach((item: { userId: number }) => {
      const target = props.data.ownerUsers.filter((user: any) => user.id === item.userId)[0] || {};
      ownersName.value = ownersName.value === '' ? target.displayName : `${ownersName.value},${target.displayName}`;
    });

    // 负责人选择
    const selectOwners = (value: any) => {
      formData.value.owner = value.owner;
      formData.value.owners = value.owners;
      formData.value.ownerUsers = value.ownerUsers;
      ownersName.value = value.ownersName;
    };

    const { classificationName, classificationValue } = useClassifications(
      formData.value.classification,
      props.classifications,
    );

    // 分类选择
    const selectClassification = (value: Array<Array<string>>) => {
      formData.value.classification = value.map((item: Array<string>) => item[item.length - 1]).join(',');
    };

    const { tagNames, tagValue } = useTags(formData.value.tags, props.tags);

    // 标签选择
    const selectTag = (tags: string[]) => {
      formData.value.tags = tags.join(',');
    };

    // 修改表单数据
    const modifyFormData = () => {
      isShowMode.value = false;
      alloverEdit.value = true;
    };

    // 保存表单修改
    const saveFormData = async () => {
      const { code } = await updateModel(formData.value, formData.value.id);
      if (code === 0) {
        isShowMode.value = true;
        alloverEdit.value = false;
        useTags(formData.value.tags, props.tags);
        useClassifications(formData.value.classification, props.classifications);
      }
    };

    return {
      isShowMode,
      formData,
      classificationName,
      classificationValue,
      selectClassification,
      ownersName,
      selectOwners,
      tagNames,
      tagValue,
      selectTag,
      modifyFormData,
      saveFormData,
    };
  },
};
