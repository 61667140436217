<template>
  <div class="drawer-content">
    <div class="drawer-content__main">
      <list-wrap :loading="loading" :inProject="false" :empty="list.length === 0" :hasCreateAuth="false">
        <el-table
          :data="list"
          style="width: 100%"
          height="calc(100% - 30px)"
          row-key="$id"
          class="apis-table"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="index" width="50" label="序号"></el-table-column>
          <el-table-column prop="name" label="接口名称" class-name="is-required">
            <template #default="scope">
              <span v-if="editId !== scope.row.$id">{{ scope.row.name }}</span>
              <el-input
                placeholder="请输入接口名称"
                v-model.trim="scope.row.name"
                maxlength="128"
                v-else
                :ref="(ref) => (inputRefs[`name.${scope.row.$id}`] = ref)"
                @input="() => clearError(`name.${scope.row.$id}`)"
                @change="(value) => handleInputChange('name', scope.row.$id, value)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="methodType" label="请求方式" width="170" class-name="is-required">
            <template #default="scope">
              <span v-if="editId !== scope.row.$id">{{ scope.row.methodType }}</span>
              <el-select
                v-else
                placeholder="请选择请求方式"
                v-model="scope.row.methodType"
                :ref="(ref) => (inputRefs[`methodType.${scope.row.$id}`] = ref)"
                @change="handleMethodTypeChange(scope.row)"
              >
                <el-option v-for="item in methodTypes" :key="item" :value="item" :label="item"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="modelId" label="数据对象" width="200" class-name="is-required" v-if="serverType === 1">
            <template #default="scope">
              <span v-if="scope.row.isSystem">通用</span>
              <template v-else>
                <span v-if="editId !== scope.row.$id">{{ getModelName(scope.row.modelId) }}</span>
                <el-select
                  v-else
                  placeholder="请选择数据对象"
                  v-model="scope.row.modelId"
                  :ref="(ref) => (inputRefs[`model.${scope.row.$id}`] = ref)"
                  @change="handleModelChange(scope.row)"
                >
                  <el-option
                    v-for="(model, index) in modelList.filter((item) => !item?.external)"
                    :key="index"
                    :label="model.name"
                    :value="model.id.toString()"
                  ></el-option>
                </el-select>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="Path" class-name="is-required">
            <template #default="scope">
              <span v-if="editId !== scope.row.$id">{{ scope.row.url }}</span>
              <el-input
                placeholder="请输入Path路径"
                v-model.trim="scope.row.url"
                maxlength="500"
                v-else
                :ref="(ref) => (inputRefs[`url.${scope.row.$id}`] = ref)"
                @input="() => clearError(`url.${scope.row.$id}`)"
                @change="(value) => handleInputChange('url', scope.row.$id, value)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="isForeign" label="是否对外">
            <template #default="scope">
              <span v-if="scope.row.isSystem">是</span>
              <template v-else>
                <span v-if="editId !== scope.row.$id">{{ scope.row.isForeign === 0 ? '否' : '是' }}</span>
                <el-select v-else v-model="scope.row.isForeign">
                  <el-option
                    v-for="item in listOptions"
                    :key="item.id"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="接口描述">
            <template #default="scope">
              <tooltip v-if="editId !== scope.row.$id" :content="scope.row.description"></tooltip>
              <el-input
                v-else
                placeholder="请输入接口描述"
                v-model.trim="scope.row.description"
                maxlength="10240"
                :ref="(ref) => (inputRefs[`description.${scope.row.$id}`] = ref)"
                @input="() => clearError(`description.${scope.row.$id}`)"
                @change="(value) => handleInputChange('description', scope.row.$id, value)"
              />
            </template>
          </el-table-column>

          <el-table-column prop="actions" label="操作" align="right" width="180">
            <template #default="scope">
              <template v-if="!scope.row.isSystem">
                <template v-if="scope.row.id && editId !== scope.row.$id">
                  <el-button type="text" @click="handleAdd(scope.row)" v-if="scope.$index === 0 && showEditBtns"
                    >添加</el-button
                  >
                  <el-button type="text" @click="handleEdit(scope.row)" v-if="showEditBtns">编辑</el-button>
                  <el-button type="text" @click="toParamsPage(scope.row)">参数</el-button>
                  <el-button type="text" @click="handleRemove(scope.row)" v-if="showEditBtns">删除</el-button>
                </template>
                <template v-else>
                  <el-button type="text" @click="handleSave(scope.row)" v-if="showEditBtns">保存</el-button>
                  <el-button
                    type="text"
                    @click="handleCancel(scope.row)"
                    :disabled="!hasCancelBtn"
                    v-if="getShowBool('apiUpdate')"
                    >取消</el-button
                  >
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div class="error-wrap">{{ formError }}</div>
      </list-wrap>
    </div>
    <div class="drawer-content__btns">
      <el-button
        type="primary"
        :loading="getDebugUrlLoading"
        :disabled="Number(status) !== 21"
        v-if="getShowBool('apiDebug')"
        @click="handleGetDebugUrl"
        >接口调试</el-button
      >
      <el-button
        @click="handleToEditStats"
        type="primary"
        :disabled="editStatus === 1"
        v-if="!isEditStats && getShowBool('apiUpdate') && !isRefrenceService"
        >编辑</el-button
      >
      <el-button @click="handleClose">{{ isEditStats ? '取消' : '关闭' }}</el-button>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { METHOD_TYPES, SYSTEM_APIS } from './config';
import { validName, validUrl, validDescription, parseList, validModel, validMethodType } from './util';
import {
  getServiceApiList,
  updateServiceApi,
  createServiceApi,
  delServiceApi,
  getServiceAPiDebugUrl,
} from '@/api/servers/index';
import _ from 'lodash';
import { genId } from '@/utils/util';
import { useRouter } from 'vue-router';
import { getShowBool } from '@/utils/permission-show-module';
import { userCurrentProject, userInfo } from '@/layout/messageCenter/user-info';
import { alloverEdit } from '@/layout/messageCenter/routerRef';
import { useEnvAndServiceId } from '@/views/env/detail/detail';
const NOT_ALLOW_CODE = 1105000;
export default defineComponent({
  props: {
    id: {
      type: String,
      default: '',
    },
    modelList: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Number,
      default: 1,
    },
    serverType: {
      type: Number,
      default: 1,
    },
    isRefrenceService: {
      type: Boolean,
      default: false,
    },
    editStatus: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const loading = ref(false);
    const isEditStats = ref(false);
    const isAdd = ref(false);
    const editId = ref('');
    const sourceList = ref([]);
    const list = ref([]);
    const inputRefs = ref({});
    const formError = ref('');
    const hasCancelBtn = ref(true);
    const getDebugUrlLoading = ref(false);
    const fetchList = async () => {
      isAdd.value = false;
      editId.value = '';
      formError.value = '';
      loading.value = true;
      const { data } = await getServiceApiList({
        serviceId: props.id,
      });
      const rowList = parseList(data || []);
      if (rowList.length === 0) {
        hasCancelBtn.value = false;
      } else {
        hasCancelBtn.value = true;
      }

      const apiList =
        props.serverType === 1
          ? [...rowList.reverse(), ...SYSTEM_APIS.filter((i) => !i.reserved)]
          : [...rowList.reverse()];
      sourceList.value = _.cloneDeep(apiList);
      list.value = _.cloneDeep(apiList);
      loading.value = false;
    };

    fetchList();

    const listOptions = ref([
      { value: 0, label: '否' },
      { value: 1, label: '是' },
    ]);

    // 进入编辑态
    const handleToEditStats = () => {
      isAdd.value = true;
      const $id = genId();
      list.value.unshift({
        $id,
      });
      editId.value = $id;
      isEditStats.value = true;
      alloverEdit.value = true;
    };

    // 清除错误
    const clearError = (refId) => {
      try {
        const ref = inputRefs.value[refId];
        ref.$el.classList.remove('is-error');
      } catch (e) {
        console.log(e);
      }
    };

    // validate after input change
    const handleInputChange = (field, $id, value) => {
      formError.value = '';
      let valid = false;
      const isProxyService = props.serverType === 1;
      switch (field) {
        case 'name':
          valid = validName(value, list.value, $id, isProxyService);
          break;
        case 'methodType':
          valid = validMethodType(value);
          break;
        case 'model':
          valid = validModel(value);
          break;
        case 'url':
          valid = validUrl(value, list.value, $id, isProxyService);
          break;
        case 'description':
          valid = validDescription(value);
          break;
      }
      if (valid) {
        const ref = inputRefs.value[`${field}.${$id}`];
        ref.$el.classList.add('is-error');
        ref.focus();
        formError.value = valid;
      }
      return valid;
    };

    const beforeEditOrAdd = async () => {
      if (editId.value || isAdd.value) {
        return ElMessageBox.confirm(`有未保存的更改，确定放弃更改吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
      }
      return Promise.resolve(true);
    };

    /**
     * 移除无效的行
     */
    const removeEmptyAddRows = () => {
      for (let i = 0, len = list.value.length; i < len; i++) {
        const row = list.value[i];
        if (row && !row.id) {
          list.value.splice(i, 1);
          i = i - 1;
        }
      }
    };

    // 新增
    const handleAdd = async (row) => {
      try {
        await beforeEditOrAdd();
        removeEmptyAddRows();
        isAdd.value = true;
        const $id = genId();
        const index = list.value.findIndex((item) => item.$id === row.$id);
        list.value.splice(index, 0, {
          $id,
        });
        editId.value = $id;
      } catch (e) {
        console.log(e);
      }
    };

    // 编辑
    const handleEdit = async (row) => {
      try {
        await beforeEditOrAdd();
        removeEmptyAddRows();
        isAdd.value = false;
        editId.value = row.$id;
      } catch (e) {}
    };

    // 删除
    const handleRemove = (row) => {
      ElMessageBox.confirm(`确定删除接口 ${row.name} 吗？`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await delServiceApi({
          serviceId: props.id,
          uniqueId: row.id,
        });
        ElMessage.success('删除成功');
        fetchList();
      });
    };

    // 保存
    const handleSave = async (row) => {
      const checkName = handleInputChange('name', row.$id, row.name);
      if (checkName) {
        return;
      }
      const checkMethodType = handleInputChange('methodType', row.$id, row.methodType);
      if (checkMethodType) {
        return;
      }
      if (props.serverType === 1) {
        const checkModel = handleInputChange('model', row.$id, row.modelId);
        if (checkModel) {
          return;
        }
      }
      const checkUrl = handleInputChange('url', row.$id, row.url);
      if (checkUrl) {
        return;
      }
      const checkDesc = handleInputChange('description', row.$id, row.description);
      if (checkDesc) {
        return;
      }

      const saveData = {
        ...row,
        serviceId: props.id,
      };
      delete saveData.$id;
      delete saveData.isSystem;
      delete saveData.modelName;
      loading.value = true;
      const isEdit = isAdd.value === false;
      let api = createServiceApi;
      if (isEdit) {
        saveData.uniqueId = row.id;
        delete saveData.id;
        api = updateServiceApi;
      }
      try {
        await api(saveData);
        ElMessage.success(isEdit ? '保存成功' : '新增成功');
        fetchList();
      } catch (e) {
        console.log(e);
        loading.value = false;
      }
    };

    // 取消
    const handleCancel = () => {
      isAdd.value = false;
      editId.value = '';
      formError.value = '';
      list.value = _.cloneDeep(sourceList.value);
    };

    const getModelName = (modelId) => {
      const models = props.modelList || [];
      const model = models.find((item) => item.id === Number(modelId));
      return model ? model.name : '';
    };

    const tableRowClassName = ({ row }) => {
      if (row.isSystem) {
        return 'system-row';
      }
    };

    const { env, envId } = useEnvAndServiceId();
    const toParamsPage = (row) => {
      const commonPath = `/${props.id}/interface/${row.id}/params`;
      let path = '';
      if (env && envId) {
        path = `/env/${env.toLocaleLowerCase()}/${envId}/service${commonPath}?editStatus=${props.editStatus}`;
      } else {
        path = `/services${commonPath}?editStatus=${props.editStatus}`;
      }
      router.push(path);
    };

    const handleClose = () => {
      if (isAdd.value || editId.value) {
        ElMessageBox.confirm(`编辑中的数据尚未保存，是否退出?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          handleCancel();
          isEditStats.value = false;
          alloverEdit.value = false;
        });
      } else {
        handleCancel();
        isEditStats.value = false;
        alloverEdit.value = false;
        emit('back');
      }
    };

    const showEditBtns = computed(() => getShowBool('apiUpdate') && isEditStats.value);

    // 获取debugUrl
    const handleGetDebugUrl = async () => {
      getDebugUrlLoading.value = true;
      try {
        const { code, message } = await getServiceAPiDebugUrl({
          userId: userInfo.value.userId,
          projectId: userCurrentProject.value.id,
        });
        if (code === 0) {
          window.open('/swagger-ui', {
            name: 'serviceDebug',
          });
        } else if (code === NOT_ALLOW_CODE) {
          ElMessage.error(message);
        }
      } catch (e) {
        console.log(e);
      }
      getDebugUrlLoading.value = false;
    };

    watch(
      () => props.id,
      () => {
        console.log(props.id, 'this is id changed');
        fetchList();
      },
    );

    const handleModelChange = (row) => {
      const id = row.$id;
      clearError(`model.${id}`);
      clearError(`url.${id}`);
      clearError(`name.${id}`);
      const checkName = row.name && handleInputChange('name', id, row.name);
      !checkName && row.url && handleInputChange('url', id, row.url);
    };

    const handleMethodTypeChange = (row) => {
      clearError(`methodType.${row.$id}`);
      clearError(`url.${row.$id}`);
      row.url && handleInputChange('url', row.$id, row.url);
    };

    return {
      isAdd,
      editId,
      list,
      inputRefs,
      formError,
      loading,
      methodTypes: [...METHOD_TYPES],
      hasCancelBtn,
      isEditStats,
      handleAdd,
      handleEdit,
      handleRemove,
      clearError,
      handleInputChange,
      handleSave,
      handleCancel,
      validName,
      getModelName,
      tableRowClassName,
      toParamsPage,
      handleClose,
      getShowBool,
      handleToEditStats,
      showEditBtns,
      handleGetDebugUrl,
      getDebugUrlLoading,
      handleModelChange,
      handleMethodTypeChange,
      listOptions,
    };
  },
});
</script>
<style lang="scss" scoped>
.apis-table {
  :deep(.el-select),
  :deep(.el-input) {
    width: 100%;
  }
  :deep(.is-error .el-input__inner) {
    border-color: #f56c6c;
  }
  :deep(.cell) {
    line-height: 28px;
  }
  :deep(.system-row) {
    background: #f5f7fa;
  }
}
.error-wrap {
  color: #f56c6c;
  height: 20px;
  line-height: 20px;
  margin: 5px 10px;
}
</style>
