import { isKeyword } from '@/utils/keyword';
import { genId } from '@/utils/util';

/**
 * 校验名称
 * @param name
 * @param list
 * @param id
 * @returns
 */
export const validName = (name: string, list: any[], id: string) => {
  if (!name) {
    return '接口名称不能为空';
  }
  if (!/^[A-Za-z]+([A-Za-z0-9]+)?$/.test(name)) {
    return '接口名称仅支持字母和数字，且不能以数字开头';
  }
  if (isKeyword(name)) {
    return `禁止使用${name}关键字`;
  }
  if (name.length > 128) {
    return '接口名称最多支持128个字符';
  }
  const nameLowerCase = name.toLocaleLowerCase();
  const currentItem = list.find((item) => item.$id === id);
  const hasRepeat = list.find((item) => {
    const isSameModel = Number(item.modelId) === Number(currentItem.modelId);
    return item.$id !== id && isSameModel && item.name.toLocaleLowerCase() === nameLowerCase;
  });
  if (hasRepeat) {
    return '接口名称已存在(不区分大小写)';
  }
  return false;
};

/**
 * 校验URL
 * @param url
 * @param list
 * @param id
 * @returns
 */
export const validUrl = (url: string, list: any[], id: string) => {
  if (!url) {
    return 'Path不能为空';
  }
  if (url.length > 500) {
    return 'Path最多支持500个字符';
  }
  if (!/^((\/[a-zA-Z0-9-_]+)*\/?)$/.test(url)) {
    return 'Path格式错误, 仅支持数字、字母、中划线（-）、下划线（_）、斜杠(/), 且必须以斜杠(/)开头';
  }
  const current = list.find((item) => item.$id === id);
  const urlLowerCase = url.toLocaleLowerCase();
  const currentItem = list.find((item) => item.$id === id);
  const hasRepeat = list.find((item) => {
    const isSameModel = Number(item.modelId) === Number(currentItem.modelId);
    return (
      item.$id !== id &&
      isSameModel &&
      current.methodType === item.methodType &&
      item.url.toLocaleLowerCase() === urlLowerCase
    );
  });
  if (hasRepeat) {
    return 'URL已存在(不区分大小写)';
  }
  return false;
};

/**
 * 校验描述
 * @param desc
 * @returns
 */
export const validDescription = (desc = '') => {
  if (desc.length > 10240) {
    return '描述最多支持10240个字符';
  }
  return false;
};

/**
 * 校验数据对象
 * @param model
 * @returns
 */
export const validModel = (model = '') => {
  if (!model) {
    return '数据对象不能为空';
  }
  return false;
};

export const validMethodType = (type = '') => {
  if (!type) {
    return '请求方式不能为空';
  }
  return false;
};
/**
 * 解析list
 * @param rows
 * @returns
 */
export const parseList = (rows: any[]) =>
  rows.map((item) => {
    const newItem: any = {};
    newItem.$id = item.uniqueId || item.$id || genId();
    newItem.id = item.uniqueId || null;
    newItem.name = item.name || '';
    newItem.url = item.url || '';
    newItem.methodType = item.methodType || '';
    newItem.description = item.description || '';
    newItem.modelId = item.modelId || '';
    newItem.modelName = item.modelName || '';
    newItem.isSystem = item.isSystem || false;
    newItem.isForeign = item.isForeign;
    return newItem;
  });
