import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0a53f20"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "btn-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_model = _resolveComponent("add-model")!
  const _component_ImportModel = _resolveComponent("ImportModel")!
  const _component_import_extrernal = _resolveComponent("import-extrernal")!
  const _component_erd_relation = _resolveComponent("erd-relation")!
  const _component_erd_external_table = _resolveComponent("erd-external-table")!
  const _component_erd_table = _resolveComponent("erd-table")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: "erd-container-wrapper",
    style: { width: _ctx.width, height: _ctx.height }
  }, _toHandlers(_ctx.handlers), {
    class: { 'erd-container_noClick': !_ctx.getShowBool('moduleSelect') }
  }), [
    _createElementVNode("div", {
      style: _normalizeStyle(`width: ${_ctx.viewWidth}px; height: ${_ctx.viewHeight}px; position: relative;`)
    }, [
      (_ctx.getShowBool('moduleUpdate') && !_ctx.isRefrenceService)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_add_model, { editStatus: _ctx.editStatus }, null, 8, ["editStatus"]),
            _createVNode(_component_ImportModel, { editStatus: _ctx.editStatus }, null, 8, ["editStatus"]),
            _createVNode(_component_import_extrernal, { editStatus: _ctx.editStatus }, null, 8, ["editStatus"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_erd_relation),
      (_ctx.allTypes.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.tables, (table, $index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: table.id
            }, [
              (table.external)
                ? (_openBlock(), _createBlock(_component_erd_external_table, {
                    key: 0,
                    index: _ctx.logs($index),
                    dragging: _ctx.logs(table.dragging),
                    class: _normalizeClass({ selected: table.selected }),
                    tableAttr: table,
                    editStatus: _ctx.editStatus,
                    types: _ctx.allTypes
                  }, null, 8, ["index", "dragging", "class", "tableAttr", "editStatus", "types"]))
                : (_openBlock(), _createBlock(_component_erd_table, {
                    key: 1,
                    editStatus: _ctx.editStatus,
                    index: _ctx.logs($index),
                    dragging: _ctx.logs(table.dragging),
                    class: _normalizeClass({ selected: table.selected }),
                    tableAttr: table,
                    types: _ctx.allTypes,
                    onDeleteTable: _ctx.deleteTable
                  }, null, 8, ["editStatus", "index", "dragging", "class", "tableAttr", "types", "onDeleteTable"]))
            ], 64))
          }), 128))
        : _createCommentVNode("", true)
    ], 4)
  ], 16))
}