export const enum JavaEntityType {
  underscope = 1,
  lowercase,
}
export type JavaEntity = {
  code: string;
  type: JavaEntityType;
};

export interface EntityStruct {
  className: string;
  fields: EntityField[];
}

export interface EntityField {
  name: string;
  description: string;
  typeId: string;
}

export type UpdateModelField = Omit<EntityField, 'typeId'> & {
  columnName: string;
};

export const enum TableNameType {
  default = 1,
  custom,
}

export interface CreateModel {
  name: string;
  description?: string;
  tableName?: string;
  serviceId: number;
}

export type ModelBaseInfo = Omit<CreateModel, 'serviceId'>;

export interface Model {
  id: number;
  updateTime: string;
  createTime: string;
  createUser: null;
  updateUser: null;
  isDelete: boolean;
  version: number;
  name: string;
  description: string;
  remark: null;
  engine: string;
  isAllByExtend: boolean;
  modelRank: string;
  classification: string;
  tags: string;
  similarObject: string;
  similarity: null;
  cloneBy: string;
  startTime: null;
  projectId: number;
  serviceId: number;
  dtoId: string;
  tableName: string;
  owners: any[];
  ownerUsers: any[];
}

export interface Field {
  id: number;
  updateTime: string;
  createTime: string;
  createUser: null;
  updateUser: null;
  version: number;
  modelId: number;
  name: string;
  columnName: string;
  typeId: number;
  description: string;
  fieldOrder: number;
  defaultValue: string;
  defaultValueType: number;
  isKey: boolean;
  isQuery: boolean;
  isShow: boolean;
  isShowList: boolean;
  queryMode: string;
  readOnly: boolean;
  notNull: boolean;
  extra: string;
  foreignModelId: null;
  foreignId: null;
  isUnique: boolean;
  isIndex: boolean;
  isParticipleSupport: boolean;
  isPinyinSupport: boolean;
  isSystem: boolean;
}

export interface Relation {
  id: number;
  updateTime: string;
  createTime: string;
  createUser: null;
  updateUser: null;
  isDelete: boolean;
  version: number;
  serviceId: number;
  fromModelId: number;
  toModelId: number;
  relationType: number;
  byFieldId: number;
}

export type ImportModel = CreateModel & {
  fields: UpdateModelField[];
};

export interface CreateCtrlDim {
  name: string;
  serviceId: number;
  modelId: number;
  modelName: string;
  fieldId: number;
  fieldName: string;
  description: string;
}
