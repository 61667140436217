import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.form,
    rules: _ctx.rules,
    ref: "formRef",
    "label-width": "100px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "Java实体类",
        prop: "code"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            class: "code-input",
            modelValue: _ctx.form.code,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.code) = $event)),
            type: "textarea",
            maxlength: "10240",
            placeholder: "请输入Java实体类",
            autosize: { minRows: 10, maxRows: 16 }
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}