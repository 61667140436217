
import { defineComponent, inject, nextTick, ref } from 'vue';
import { createModel } from '@/api/schema/model';
import { ElForm, ElMessage } from 'element-plus';
import useTableName from './useTableName';
import { ModelBaseInfo } from './types';

export default defineComponent({
  name: 'AddModel',
  props: {
    editStatus: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const serviceId = inject('serviceId') as number;
    const erdEmit = inject('erdEmit') as Function;
    const formRef = ref<InstanceType<typeof ElForm>>();
    const dialogVisible = ref(false);
    const submitting = ref(false);
    const MAX_LEN_OF_NAME = 47;
    const form = ref<ModelBaseInfo>({
      name: '',
      description: '',
      tableName: '',
    });
    const objectName = ref(null as any);
    const objectDescription = ref(null as any);
    const showDialog = () => {
      dialogVisible.value = true;
      nextTick(() => {
        form.value = {
          name: '',
          description: '',
          tableName: '',
        };
        // eslint-disable-next-line no-unused-expressions
        formRef.value?.clearValidate();
      });
    };

    const strLenExceeded = (str: string) => {
      const uppercaseChar = (str.match(/[A-Z]/g) ?? []).length; // 所有大写字母的长度
      return uppercaseChar - 1 + str.length > MAX_LEN_OF_NAME;
    };
    const addModel = async () => {
      try {
        submitting.value = true;

        await formRef.value?.validate();

        const { code } = await createModel({
          ...form.value,
          serviceId,
        });

        if (code !== 0) {
          throw new Error('创建失败');
        }

        dialogVisible.value = false;
        ElMessage.success('创建成功');
        erdEmit('model-change');
      } catch (error) {
        console.log(error);
        submitting.value = false;
      }
      submitting.value = false;
    };
    const validateName = (rule: any, value: string, callback: Function) => {
      if (strLenExceeded(value)) {
        // 大驼峰转下划线长度加上大写字母长度 - 1
        callback(new Error(`驼峰转下划线后不超过${MAX_LEN_OF_NAME}个字符`));
      } else {
        callback();
      }
    };
    const { TABLE_NAME_OPTIONS, curTableNameType, tableNameRules } = useTableName(form);
    const rules = {
      name: [
        { required: true, message: '请输入对象名称', trigger: 'blur' },
        {
          pattern: /^([A-Z][a-z]+)+$/,
          message: '名称必须是大驼峰格式，均为英文字母，请重新输入',
          trigger: 'blur',
        },
        { validator: validateName, trigger: 'blur' },
      ],
      tableName: tableNameRules,
      description: [
        { required: true, message: '请输入对象描述', trigger: 'blur' },
        { max: 255, message: '长度不可超过255个字符', trigger: 'blur' },
      ],
    };

    return {
      form,
      dialogVisible,
      showDialog,
      addModel,
      rules,
      formRef,
      objectName,
      objectDescription,
      submitting,
      TABLE_NAME_OPTIONS,
      curTableNameType,
    };
  },
});
