
import { defineComponent, computed, inject, ref } from 'vue';
import { drawRelationStart, drawTempRleation, removeTempRleation, tables } from './store';
import { deleteModel } from '@/api/schema/model';
import { ElMessage, ElMessageBox } from 'element-plus';
import { relationIdArray, serverInfo } from '@/views/service-management/business-service/utils/service-detail-data';

export default defineComponent({
  name: 'ErdTable',
  props: {
    index: {
      type: Number,
      required: true,
    },
    dragging: {
      type: Number,
      default: 0,
    },
    tableAttr: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    editStatus: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const erdEmit = inject('erdEmit') as Function;
    const markers = ['top', 'right', 'bottom', 'left'];
    const disabled = ref(false);
    const removeModel = async (table: any) => {
      if (relationIdArray.value.includes(table.id)) {
        return ElMessage.error('请先删除关联关系');
      }
      ElMessageBox.confirm(`确认删除对象${table.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code } = await deleteModel({
            id: table.id,
            serviceId: serverInfo.value.id,
          });
          if (code === 0) {
            erdEmit('model-change');
          }
          erdEmit('delete-model');
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const table = computed(() => props.tableAttr);
    const toggleShowMore = (table: any, bool: boolean) => {
      // eslint-disable-next-line no-param-reassign
      table.showMore = bool;
      tables.value[props.index].showMore = bool;
    };

    return {
      markers,
      drawRelationStart,
      drawTempRleation,
      removeTempRleation,
      removeModel,
      table,
      disabled,
      serverInfo,
      toggleShowMore,
    };
  },
});
