
import { Model } from '@/components/data-model/erd/types';
import { defineComponent, inject, Ref, watch, ref } from '@vue/runtime-core';

export default defineComponent({
  name: 'EditModel',
  setup() {
    const currentModel = inject('currentModel') as Ref<Model | null>;
    const formData = ref<Pick<Model, 'name' | 'tableName' | 'description'>>({
      name: '',
      tableName: '',
      description: '',
    });

    const initState = () => {
      const { name = '', tableName = '', description = '' } = currentModel.value ?? {};
      formData.value = {
        name,
        tableName,
        description,
      };
    };

    initState();

    watch(currentModel, () => {
      initState();
    });
    return {
      formData,
    };
  },
});
