<template>
  <div class="standard-field">
    <el-button @click="handleOpenDialog">标准属性</el-button>
    <el-dialog title="标准属性设置" :width="640" v-model="visible" @close="handleCloseDialog" destroy-on-close>
      <div class="standard-field__body" v-loading="loading">
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <el-checkbox-group v-model="checkedFields" @change="handleCheckedChange" style="margin: 20px 0">
          <el-checkbox v-for="field in fields" :key="field.name" :label="field.name">{{
            field.description
          }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleSubmit">确定</el-button>
          <el-button @click="handleCloseDialog">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { getStandardFields } from '@/api/schema/model';
import { defineComponent, ref } from 'vue';

let standardFieldsList = null;
export default defineComponent({
  name: 'StandardField',
  props: {
    fieldsList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const fields = ref([]);
    const loading = ref(true);
    const checkAll = ref(false);
    const isIndeterminate = ref(false);
    const checkedFields = ref([]);

    const getStandardFieldsList = async () => {
      if (standardFieldsList) {
        return Promise.resolve(standardFieldsList);
      }
      const { data } = await getStandardFields();
      standardFieldsList = data;
      return data;
    };

    const initCheckedFields = () => {
      const standardFields = props.fieldsList
        .filter((item) => fields.value.find((f) => f.name === item.name))
        .map((item) => item.name);
      checkedFields.value = standardFields;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleCheckedChange(standardFields);
    };

    const handleOpenDialog = async () => {
      loading.value = true;
      visible.value = true;
      fields.value = await getStandardFieldsList();
      initCheckedFields();
      loading.value = false;
    };

    const handleCloseDialog = () => {
      visible.value = false;
      loading.value = false;
    };

    const handleCheckAllChange = (val) => {
      checkedFields.value = val ? fields.value.map((item) => item.name) : [];
      isIndeterminate.value = false;
    };

    const handleCheckedChange = (val) => {
      const checkedCount = val.length;
      const size = fields.value.length;
      checkAll.value = checkedCount === size;
      isIndeterminate.value = checkedCount > 0 && checkedCount < size;
    };

    const handleSubmit = () => {
      const fieldsList = [];
      const originStandardFieldList = [];
      props.fieldsList.forEach((item) => {
        const isStandardField = fields.value.find((f) => f.name === item.name);
        if (isStandardField) {
          // 验证下是否存在于已选列表中(保留其ID)
          const isChecked = checkedFields.value.find((f) => f === item.name);
          if (isChecked) {
            originStandardFieldList.push(item);
          }
        } else {
          fieldsList.push(item);
        }
      });
      const newCheckedFields = checkedFields.value.map((name) => {
        let field = originStandardFieldList.find((f) => f.name === name);
        if (!field) {
          field = fields.value.find((f) => f.name === name);
        }
        return field;
      });
      emit('fields-list-change', [...fieldsList, ...newCheckedFields]);
      handleCloseDialog();
    };

    return {
      visible,
      checkAll,
      checkedFields,
      isIndeterminate,
      fields,
      handleOpenDialog,
      handleCloseDialog,
      handleCheckAllChange,
      handleCheckedChange,
      handleSubmit,
    };
  },
});
</script>
<style lang="scss" scoped>
.standard-field__body {
  min-height: 90px;
  position: relative;
}
</style>
