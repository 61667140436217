import { ref, Ref, watch } from 'vue';
import { ModelBaseInfo, TableNameType } from './types';

export const tableNameRules = [
  { required: true, message: '请输入表名', trigger: 'blur' },
  {
    pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
    message: '只允许西文字母（大小写均可）、数字、下划线，且不能以数字开头',
    trigger: 'blur',
  },
  {
    max: 47,
    message: '长度不可超过47个字符',
    trigger: 'blur',
  },
];
export default function (modelData: Ref<ModelBaseInfo>) {
  const curTableNameType = ref<TableNameType>(TableNameType.default);
  const TABLE_NAME_OPTIONS = [
    {
      label: '默认',
      value: TableNameType.default,
    },
    {
      label: '自定义',
      value: TableNameType.custom,
    },
  ];

  const camelCaseToUnderScope = (str: string) => str.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`).replace(/^_/, '');

  watch([curTableNameType, () => modelData.value.name], () => {
    if (curTableNameType.value === TableNameType.default) {
      // eslint-disable-next-line no-param-reassign
      modelData.value.tableName = camelCaseToUnderScope(modelData.value.name);
    }
  });

  return {
    TABLE_NAME_OPTIONS,
    curTableNameType,
    tableNameRules,
  };
}
