<template>
  <el-popover :width="400" trigger="click" placement="top">
    <template #reference>
      <div class="default-value__input" tabindex="1">
        <span>{{ value }}</span>
      </div>
    </template>
    <div class="default-value__panel">
      <div class="item">
        <el-radio name="defaultValue" v-model="type" :label="DEFAULT_VALUE_TYPE.NONE" @change="handleTypeChange"
          >无默认值</el-radio
        >
      </div>
      <div class="item" v-if="customAvailable">
        <el-radio name="defaultValue" v-model="type" :label="DEFAULT_VALUE_TYPE.CUSTOM" @change="handleTypeChange"
          >自定义字符串</el-radio
        >
        <el-input
          v-model="inputValue"
          :disabled="type !== DEFAULT_VALUE_TYPE.CUSTOM"
          @change="handleValueChange"
          clearable
        ></el-input>
      </div>
      <div class="item">
        <el-radio
          name="defaultValue"
          v-model="type"
          :label="DEFAULT_VALUE_TYPE.NULL"
          :disabled="notNull"
          @change="handleTypeChange"
          >Null</el-radio
        >
      </div>
      <div class="item" v-if="expressionAvailable">
        <el-radio name="defaultValue" v-model="type" :label="DEFAULT_VALUE_TYPE.EXPRESSION" @change="handleTypeChange"
          >表达式</el-radio
        >
        <el-select
          v-model="expressionValue"
          :disabled="type !== DEFAULT_VALUE_TYPE.EXPRESSION"
          @change="handleValueChange"
          placeholder="请选择"
        >
          <el-option
            v-for="item in customOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { computed, defineComponent, ref, watch, watchEffect } from 'vue';
import { DEFAULT_VALUE_TYPE } from '@/views/service-management/business-service/components/defaultValueType';

export default defineComponent({
  name: 'DefaultValue',
  props: {
    defaultValue: {
      type: [String, Number, Object],
      default: '',
    },
    defaultValueType: {
      type: Number,
      default: 0,
    },
    notNull: {
      type: Boolean,
      default: false,
    },
    dbType: {
      type: String,
      default: '',
    },
    dbTypes: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const value = ref(props.defaultValue);
    const sourceValue = _.cloneDeep(props.defaultValue);
    const type = ref(props.defaultValueType);

    const inputValue = ref('');
    const expressionValue = ref('');

    // 表达式下拉列表
    const customOptions = ref([
      {
        value: 'CURRENT_TIMESTAMP',
        label: 'CURRENT_TIMESTAMP',
      },
      {
        value: 'CURRENT_TIMESTAMP ON UPDATE CURRENT_TIMESTAMP',
        label: 'CURRENT_TIMESTAMP ON UPDATE CURRENT_TIMESTAMP',
      },
    ]);

    const currentDbType = ref(props.dbType);
    watch(
      () => props.dbType,
      (newVal) => {
        currentDbType.value = newVal;
      },
    );

    // 是否可以自定义
    const customAvailable = computed(
      () => props.dbTypes.find((item) => item.dbType === currentDbType.value)?.customDefaultValueEnable,
    );
    // 时间类型可以表达式
    const expressionAvailable = computed(
      () => props.dbTypes.find((item) => item.dbType === currentDbType.value)?.expressionDefaultItems !== null,
    );

    const handleValueChange = (val) => {
      emit('update:defaultValue', val);
    };

    const handleTypeChange = (type) => {
      switch (type) {
        case DEFAULT_VALUE_TYPE.NONE:
          handleValueChange('');
          break;
        case DEFAULT_VALUE_TYPE.CUSTOM:
          handleValueChange(inputValue.value);
          break;
        case DEFAULT_VALUE_TYPE.NULL:
          handleValueChange('NULL');
          break;
        case DEFAULT_VALUE_TYPE.EXPRESSION:
          handleValueChange(expressionValue.value);
      }
      emit('update:defaultValueType', type);
    };

    watch(
      () => props.defaultValue,
      (nv) => {
        value.value = nv;
      },
    );

    watch(
      () => props.defaultValueType,
      (nv) => {
        type.value = nv;
      },
    );

    watchEffect(() => {
      switch (type.value) {
        case DEFAULT_VALUE_TYPE.CUSTOM:
          inputValue.value = sourceValue;
          break;
        case DEFAULT_VALUE_TYPE.EXPRESSION:
          expressionValue.value = sourceValue;
          break;
        default:
          inputValue.value = '';
          expressionValue.value = '';
      }
    });

    return {
      value,
      type,
      handleTypeChange,
      handleValueChange,
      customOptions,
      inputValue,
      expressionValue,
      sourceValue,
      customAvailable,
      expressionAvailable,
      DEFAULT_VALUE_TYPE,
    };
  },
});
</script>

<style lang="scss">
.default-value__panel {
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .el-radio {
      margin: 5px 0;
    }
    .el-input__inner {
      vertical-align: middle;
    }
  }
}
.default-value__input {
  border: 1px solid #dcdfe6;
  padding: 0 15px;
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  &:focus-within {
    border-color: #409eff;
  }
}
</style>
