<template>
  <div class="add-model-btn external-btn" @click="toggleDialog" v-if="editStatus === 0">
    <i class="el-icon-plus"></i>
    <span>只读关联外部数据对象</span>

    <el-dialog v-model="dialogVisible" title="只读关联外部数据对象" width="450px" append-to-body>
      <el-form label-width="120px" label-position="left" :model="form">
        <el-form-item label="依赖服务" required>
          <el-select v-model="form.targetServiceName" style="width: 250px; border-radius: 0">
            <el-option v-for="server in services" :key="server" :value="server" :label="server">
              <service-name :name="server"></service-name>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="外部数据对象" required>
          <el-select v-model="form.targetModelName" style="width: 250px; border-radius: 0">
            <el-option v-for="model in currentList" :key="model" :value="model" :label="model"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="importExternal">确定</el-button>
        <el-button @click="toggleDialog">取消</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { computed, defineComponent, inject, ref, watch } from 'vue';
import {
  currentServiceIdForData,
  externalModels,
} from '@/views/service-management/business-service/utils/service-detail-data.ts';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { useRoute } from 'vue-router';
import { doImportExternal, getExternalModelUsable } from '@/api/servers';
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: 'ImportExternal',
  props: {
    editStatus: {
      type: Number,
      default: 0,
    },
  },
  components: { ServiceName },
  setup() {
    const erdEmit = inject('erdEmit');
    const dialogVisible = ref(false);

    const route = useRoute();
    const id = route.params?.id;

    const services = ref([]);
    const models = ref({});
    const currentList = ref({});

    const hadModels = computed(() => externalModels.value.map((x) => x.name));

    const form = ref({
      targetServiceName: services.value[0],
      targetModelName: currentList.value[0],
    });

    const toggleDialog = () => {
      dialogVisible.value = !dialogVisible.value;
      if (!dialogVisible.value) {
        form.value.targetServiceName = '';
        form.value.targetModelName = '';
      }
    };

    const getExternalMap = async (id) => {
      const { data } = await getExternalModelUsable(id);
      services.value = Object.keys(data);
      models.value = data;
      // eslint-disable-next-line prefer-destructuring
      form.value.targetServiceName = services.value[0];
    };

    getExternalMap(id);

    watch(dialogVisible, (nn) => {
      if (nn) {
        getExternalMap(id);
      }
    });

    const importExternal = async () => {
      form.value.serviceId = +currentServiceIdForData.value;
      const res = await doImportExternal(form.value);
      if (res.code === 0) {
        ElMessage.success('导入成功');
      }
      dialogVisible.value = false;
      erdEmit('model-change');
    };

    watch(
      () => form.value.targetServiceName,
      (nn) => {
        currentList.value = models.value[nn];
        // eslint-disable-next-line prefer-destructuring
        form.value.targetModelName = currentList.value[0];
      },
    );

    return {
      dialogVisible,
      toggleDialog,
      form,
      currentServiceIdForData,
      services,
      currentList,
      importExternal,
      hadModels,
    };
  },
});
</script>

<style lang="scss">
.external-btn {
  position: relative;
  width: 150px;
  height: 100px;
  border: 1px dashed #ccc;
  // margin: 20px 0 0 20px;
  text-align: center;
  color: #999;
  z-index: 1;
  i {
    line-height: 80px;
    font-size: 22px;
  }
  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 60px;
    left: 0;
    font-size: 14px;
  }
  &:hover {
    color: #0595db;
    border-color: #0595db;
    background: #fcfcfc;
    cursor: pointer;
  }
}
.el-input--small .el-input__inner {
  border-radius: 0 !important;
}
.el-button-primary {
  background-color: #006eff !important;
}
</style>
