
import { EntityField, JavaEntityType } from '@/components/data-model/erd/types';
import { tableNameRules } from '@/components/data-model/erd/useTableName';
import { underScopeToCamelCase } from '@/utils/util';
import { ElForm } from 'element-plus';
import { defineComponent, reactive, ref, PropType, watch } from 'vue';
import useDataType from './useDataType';
export default defineComponent({
  name: 'ConfigForm',
  props: {
    tableName: {
      type: String as PropType<string>,
      default: '',
    },
    fields: {
      type: Array as PropType<EntityField[]>,
      default: () => [],
    },
  },
  setup(props) {
    const form = reactive({
      tableName: props.tableName,
      namedType: JavaEntityType.underscope,
    });
    const { allDataTypes } = useDataType();

    watch(
      () => props.tableName,
      (v) => {
        form.tableName = v;
      },
    );

    const rules = {
      tableName: tableNameRules,
    };
    const formRef = ref<InstanceType<typeof ElForm>>();
    // 自定义类型时生成dbTypeName
    const generateDbTypeName = (field: any) => {
      if (field.length !== null && field.precision !== null) {
        return `${field.dbType} (${field.length},${field.precision})`;
      }
      if (field.length && field.precision === null) {
        return `${field.dbType} (${field.length})`;
      }
      return field.dbType;
    };
    const handleSubmit = async () => {
      const valid = await formRef.value?.validate();
      if (!valid) {
        throw new Error('数据库表名校验失败');
      }

      // fields
      const fields: any[] = props.fields.map((item) => ({
        ...item,
        dbTypeName: generateDbTypeName(item),
        name: underScopeToCamelCase(item.name), // camelCase
        columnName:
          form.namedType === JavaEntityType.underscope
            ? item.name.replace(/([A-Z])/g, '_$1').toLowerCase()
            : item.name.toLowerCase(),
      }));
      return {
        tableName: form.tableName,
        fields,
      };
    };

    const transformName = (name: string) => {
      if (form.namedType === JavaEntityType.underscope) {
        return name;
      }
      return name.replace(/_/g, '');
    };
    const getDataTypeName = (typeid: string) => {
      const type = allDataTypes.value.find((item) => item.id === Number(typeid));
      return type?.name ?? typeid;
    };

    return {
      form,
      rules,
      formRef,
      handleSubmit,
      transformName,
      getDataTypeName,
      generateDbTypeName,
    };
  },
});
