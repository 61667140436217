
import { importModel } from '@/api/schema/model';
import { EntityStruct } from '@/components/data-model/erd/types';
import { underScopeToUpperCamelCase } from '@/utils/util';
import { defineComponent, ref, inject } from 'vue';
import BaseForm from './Base.vue';
import ConfigForm from './Config.vue';
export default defineComponent({
  name: 'ImportModelModal',
  components: {
    BaseForm,
    ConfigForm,
  },
  setup() {
    const finishing = ref(false);
    const step = ref(0);
    const visible = ref(false);
    const baseRef = ref<InstanceType<typeof BaseForm>>();
    const configModelRef = ref<InstanceType<typeof ConfigForm>>();
    const entity = ref<EntityStruct>();
    const serviceId = inject('serviceId') as number;
    const erdEmit = inject('erdEmit') as (data: any) => void;

    const loadingEntity = ref(false);

    const creatingModel = ref(false);
    // 开启对话框
    const openDialog = () => {
      visible.value = true;
      step.value = 0;
    };

    // 关闭dialog
    const closeDialog = () => {
      visible.value = false;
    };

    const handleNext = async () => {
      loadingEntity.value = true;
      const $base = baseRef.value;
      if (!$base) return;
      try {
        entity.value = await $base.handleSubmit();
        step.value = 1;
        loadingEntity.value = false;
      } catch (error) {
        console.log(error);
        loadingEntity.value = false;
      }
    };

    const handlePrev = async () => {
      step.value = 0;
    };
    const onSubmit = async () => {
      // 创建模型
      creatingModel.value = true;
      try {
        if (configModelRef.value === undefined) return;
        const { tableName, fields } = await configModelRef.value.handleSubmit();
        const { code } = await importModel({
          name: underScopeToUpperCamelCase(entity.value?.className ?? ''), // 服务端接受大驼峰命名，这里需要转换
          tableName,
          description: '',
          serviceId,
          fields,
        });
        if (code === 0) {
          // import success
          creatingModel.value = false;
          closeDialog();
          erdEmit('model-change');
        }
      } catch (error) {
        creatingModel.value = false;
        console.log(error);
      }
    };

    return {
      finishing,
      step,
      visible,
      baseRef,
      entity,
      loadingEntity,
      configModelRef,
      openDialog,
      closeDialog,
      handleNext,
      handlePrev,
      onSubmit,
    };
  },
});
