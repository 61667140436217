
import { defineComponent, inject, ref, Ref } from 'vue';
import EditModel from './EditModel.vue';
import ModelFieldForm from './FieldForm.vue';
import ModelIndexForm from './IndexForm.vue';

export default defineComponent({
  name: 'ModelDetailForm',
  components: {
    EditModel,
    ModelFieldForm,
    ModelIndexForm,
  },
  props: {
    modelLoading: {
      type: Boolean,
      default: true,
    },
    editStatus: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {
    console.log(props, context);
    const currentModel = inject('currentModel') as Ref<any>;
    const fieldsRef: Ref<any> = ref(null);
    const indexRef: Ref<any> = ref(null);

    const activeName = ref('fields');
    const handleClick = (activeName: string, oldActiveName: string) => {
      const componentRef = oldActiveName === 'fields' ? fieldsRef : indexRef;
      // eslint-disable-next-line no-unused-expressions
      return componentRef.value?.handelChange();
    };
    const closeDrawer = () => {
      context.emit('back');
    };
    return {
      currentModel,
      fieldsRef,
      indexRef,
      activeName,
      handleClick,
      closeDrawer,
    };
  },
});
