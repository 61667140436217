
import { reactive, ref, inject, watch } from 'vue';
import useClassifications from '../utils/service-baseinfo-classification';
import useTags from '../utils/service-baseinfo-tag';
import { updateService } from '@/api/servers';
import { dependenciesList, getServiceDependencies } from '../utils/service-data-utils';
import { ElMessage } from 'element-plus';
import { getShowBool } from '@/utils/permission-show-module';
import { getServiceShowName } from '../../components/utils';
import { isRefrence } from '../utils/permisson';
import { alloverEdit } from '@/layout/messageCenter/routerRef';

export default {
  name: 'ServerBaseInfo',
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: Number,
      default: 0,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    classifications: {
      type: Array,
      default: () => [],
    },
  },
  setup(props: { data: any; id: number; tags: any[]; classifications: any[] }, ctx: any) {
    getServiceDependencies(props.id, props.data.name);
    // 是否为显示模式标识，默认为true
    const isShowMode = ref(true);

    const serviceCascaderProps = ref({
      multiple: true,
    } as any);
    const isRefrenceService = inject(isRefrence); // inject isRefrence
    const ownersArr = props.data.owners?.map((x: any) => x.userId) || [];
    const allOwnersArr = props.data.ownerUsers?.map((x: any) => x.id) || [];
    const realOwners = ownersArr
      // eslint-disable-next-line array-callback-return
      .map((x: any) => {
        if (allOwnersArr.includes(x)) {
          return { userId: x };
        }
      })
      .filter((x: any) => x);

    // 表单数据
    const formData = reactive({
      name: props.data.name,
      description: props.data.description,
      owner: props.data.owner,
      owners: realOwners,
      ownerUsers: props.data.ownerUsers,
      classification: props.data.classification,
      tag: props.data.tag,
      detail: props.data.detail,
      serviceDependencies: props.data.dependencyServices || props.data.serviceDependencies,
    });

    watch(
      () => props.data.serviceDependencies,
      (v) => {
        formData.serviceDependencies = v;
      },
    );

    // 负责人名称
    const ownersName = ref('');

    // 初始化负责人名称
    formData.owners.forEach((item: { userId: number }) => {
      const target = props.data.ownerUsers.filter((user: any) => user.id === item.userId)[0] || {};
      ownersName.value = ownersName.value === '' ? target.displayName : `${ownersName.value},${target.displayName}`;
    });

    // 负责人选择
    const selectOwners = (value: any, users: any) => {
      formData.owner = value;
      formData.owners = users;
      ownersName.value = users.map((item: any) => item.displayName).join(',');
    };

    const classData = useClassifications(formData.classification, props.classifications);
    const classificationValue = ref(+formData.classification);
    const classificationName = ref(classData.classificationName);

    // 分类选择
    const selectClassification = (value: any) => {
      formData.classification = `${value}`;
      classificationValue.value = value;
    };

    const { tagValue, tagNames } = useTags(formData.tag, props.tags);

    // 标签选择
    const selectTag = (tags: string[]) => {
      formData.tag = tags.join(',');
    };

    // 修改表单数据
    const modifyFormData = () => {
      isShowMode.value = false;
      alloverEdit.value = true;
    };

    // 保存表单修改
    const saveFormData = async () => {
      const data = { ...formData };
      if (data.owners.length > 10) {
        return ElMessage.warning('负责人最多支持10个');
      }
      const { code } = await updateService(String(props.id), data);
      if (code === 0) {
        isShowMode.value = true;
        alloverEdit.value = false;
        useTags(formData.tag, props.tags);
        useClassifications(formData.classification, props.classifications);
        ctx.emit('changeSource');
      }
    };
    const dependencyNameAndVersion = (data: any) => {
      const { dependencyServiceName, dependencyServiceVersion } = data;
      return `${getServiceShowName(dependencyServiceName)}/${dependencyServiceVersion}`;
    };
    return {
      isShowMode,
      formData,
      classificationName,
      classificationValue,
      selectClassification,
      ownersName,
      tagValue,
      tagNames,
      selectOwners,
      selectTag,
      modifyFormData,
      saveFormData,
      getShowBool,
      dependenciesList,
      serviceCascaderProps,
      dependencyNameAndVersion,
      isRefrenceService,
    };
  },
};
