
import { defineComponent, computed, inject, ref } from 'vue';
import { drawRelationStart, drawTempRleation, removeTempRleation } from './store';
import { ElMessageBox } from 'element-plus';
import { serverInfo } from '@/views/service-management/business-service/utils/service-detail-data';
import { deleteImportExternal } from '@/api/servers';
import { getServiceShowName } from '@/views/service-management/components/utils';

export default defineComponent({
  name: 'ErdTable',
  props: {
    index: {
      type: Number,
      required: true,
    },
    dragging: {
      type: Number,
      default: 0,
    },
    tableAttr: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const erdEmit = inject('erdEmit') as Function;
    const markers = ['top', 'right', 'bottom', 'left'];
    const disabled = ref(false);
    const removeModel = async (table: any) => {
      ElMessageBox.confirm(`确认删除导入的对象${table.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code } = await deleteImportExternal({ modelName: table.name, serviceId: serverInfo.value.id });
          if (code === 0) {
            erdEmit('model-change');
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    console.log(serverInfo.value, 'service info');
    const table = computed(() => props.tableAttr);
    return {
      markers,
      drawRelationStart,
      drawTempRleation,
      removeTempRleation,
      removeModel,
      table,
      disabled,
      serverInfo,
      getServiceShowName,
    };
  },
});
