import { getDataTypesAll } from '@/api/settings/data-types';
import { ref } from 'vue';
import { ModelDataType } from './types';
const allDataTypes = ref<ModelDataType[]>([]);
export default function () {
  const fetchDataTypes = async () => {
    if (allDataTypes.value.length !== 0) return;
    try {
      const { code, data } = await getDataTypesAll();
      if (code !== 0) {
        throw new Error('获取数据类型失败');
      }
      allDataTypes.value = data;
    } catch (error) {
      console.log(error);
    }
  };
  fetchDataTypes();
  return {
    allDataTypes,
  };
}
