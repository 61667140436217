
import useButtonUtils from './utils/service-detail-utils';
import ServerBaseInfo from './components/ServerBaseInfo.vue';
import Erd from '@/components/data-model/erd/Index.vue';
import ServerApiList from '../api/List.vue';
import { ref, Ref, watch, provide, computed, onBeforeUnmount, getCurrentInstance, readonly, nextTick } from 'vue';
import RelationInfo from './components/RelationInfo.vue';
import ModelFieldForm from './components/FieldForm.vue';
import ModelDetailForm from './components/ModelDetail.vue';
import ModelBaseInfo from './components/ModelBaseInfo.vue';
import ServerConfigInfo from './components/ServerConfigInfo.vue';
import {
  getServiceList,
  getServiceById,
  updateServiceStatus,
  releaseCheck,
  getPreDataStart,
  startService,
} from '@/api/servers';
import { getAllTags } from '@/api/settings/tags';
import { getClassificationList } from '@/api/settings/classification';
import { getServiceModelList, getModelDetail } from '@/api/schema/model';
import { getDataTypesAll } from '@/api/settings/data-types';
import { getShowBool } from '@/utils/permission-show-module';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import DtoList from '../dto/DtoList.vue';
import { userInfo, userProjectList } from '@/layout/messageCenter/user-info';
import {
  statusMap,
  computeStatusLabel,
  statusColor,
} from '@/views/service-management/business-service/utils/service-status-map';
import {
  currentServiceIdForData,
  handleChangeApply,
  thenRefresh,
  serverInfo,
  serverList,
  externalModels,
  relationIdArray,
} from './utils/service-detail-data';
import _ from 'lodash/fp';
import {
  releaseDialogVisible,
  closeReleaseDialog,
  releaseStart,
} from '@/views/service-management/business-service/utils/service-release-data-utils';

import ReleaseDialog from './components/ReleaseDialog.vue';
import { getServiceShowName } from '../components/utils';
import { currentServiceSourceAllowed, useCheckRefrenceService } from './utils/permisson';
import ServiceBase from '@/views/service-repository/detail/Base.vue';
import { curServiceInfo, isProxyService, SERVICE_APPEARANCE } from './useAddProxy';
import { useForceUpdate } from './components/useVerionInput';
import DomainConfig from '@/views/service-management/business-service/components/domainConfig/DomainConfig.vue';
import LogDialog from './components/LogDialog.vue';
import useTenant from '@/views/tenant-management/useTenant';
import { getAndReduceExternal } from '@/views/service-management/business-service/utils/service-data-utils';
import { useEnvAndServiceId } from '@/views/env/detail/detail';
import { removeEnvStorage } from '@/utils/env-storage';
import Version from '@/views/application-module/components/upgrade-instance/Version.vue';
import ServerDependency from './components/service-dependency/ServerDependency.vue';
import { queryMergeStatus } from '@/api/mergeDeploy';

interface RefDialog {
  openDialog: Function;
  [attr: string]: any;
}

export default {
  name: 'ServiceDetail',
  components: {
    ServiceBase,
    ServerBaseInfo,
    Erd,
    ModelDetailForm,
    ModelFieldForm,
    ServerApiList,
    RelationInfo,
    ModelBaseInfo,
    ServerConfigInfo,
    ReleaseDialog,
    ServiceName,
    DtoList,
    DomainConfig,
    LogDialog,
    Version,
    ServerDependency,
  },
  setup() {
    // 获取路由信息
    const { serviceId, envName, envId, env } = useEnvAndServiceId() as any;
    console.log(serviceId);
    // 当前服务ID
    const currentServiceId = ref(serviceId);
    currentServiceIdForData.value = serviceId;
    console.log('currentServiceIdForData', currentServiceIdForData.value);

    const logDialogRef = ref(null as any);
    const erdRef = ref(null as any);
    const editStatus = ref(0 as any);
    provide('editStatus', editStatus);

    const getLog = () => {
      logDialogRef.value.handleOpenDialog();
    };
    const domainRef = ref<InstanceType<typeof DomainConfig>>();
    const { tenant, fetchTenantInfo } = useTenant();
    if (!tenant) {
      fetchTenantInfo();
    }
    const { buttons } = useButtonUtils({
      applyChange: async () => await handleChangeApply(),
      getLog,
      tenant,
      domainRef,
    });

    // 获取this指针
    const { proxy } = getCurrentInstance() as any;

    // 是否显示底部抽屉
    const isShowDownDrawer = ref(false);

    const computedHeight = computed(() => (isShowDownDrawer.value ? 'calc(95% - 400px)' : '95%'));

    const { renderKey, forceUpdate: forceUpdateBaseInfo } = useForceUpdate();

    const modelInfo = ref(null);

    // 属性列表是否已打开
    const isOpenProperties = ref(false);

    // 获取组件实例
    const instance = getCurrentInstance();
    // 提示信息
    function msgTips(type: string, content: string) {
      (instance as any).proxy.$message({
        type,
        message: content,
      });
    }
    // 发版前提示状态
    const relDiavisible = ref(false);

    // 发版前依赖未更新返回数据
    const releseList = ref([]);

    const getServerList = async () => {
      const { data } = await getServiceList({ all: true });
      data.rows.forEach((x: any) => {
        // eslint-disable-next-line no-param-reassign
        x.shortName = x.name ? getServiceShowName(x.name) : '';
      });
      serverList.length = 0;
      serverList.push(...(data.rows || []));
    };

    getServerList();
    const loading = ref(true);

    // 服务详情信息

    // erd图组件参数构造
    provide('serviceId', currentServiceId.value);
    provide('serverInfo', serverInfo);
    provide('serverList', serverList);
    provide(curServiceInfo, readonly(serverInfo));
    const { isRefrenceService } = useCheckRefrenceService(serverInfo);
    const erdLoading = ref(false);
    const modelList: Ref<any> = ref({
      tables: [],
      relations: [],
    });
    // 获取模型列表
    const initModelList = async () => {
      // erdLoading.value = true;
      const { code, data } = await getServiceModelList({
        serviceId: currentServiceId.value,
      });
      const externals = await getAndReduceExternal(currentServiceId.value);
      externalModels.value = externals || [];
      // erdLoading.value = false;
      let tables: any[] = [];
      let relations: any[] = [];
      if (code === 0) {
        tables = data.models.concat(externals || []);
        relationIdArray.value = [];
        relations = _.map((relation: any) => {
          relationIdArray.value.push(relation.fromModelId);
          relationIdArray.value.push(relation.toModelId);
          return [
            _.findIndex({ id: relation.fromModelId })(tables),
            _.findIndex({ id: relation.toModelId })(tables),
            relation.relationType,
            relation.id,
          ];
        })(data.relations);
      }
      let offset = 0;
      const tableMap: any = {};
      modelList.value.tables.forEach((table: any) => {
        tableMap[table.id] = table.position;
      });
      // 模型无定位时增加默认定位
      tables.forEach((table: any) => {
        const tablePosition = serverInfo.value?.config?.coordinate[table.name];
        const oldTablePosition = tableMap[table.id];
        // modelList.value.tables[index]?.position;
        if ((oldTablePosition && !oldTablePosition.temp) || (tablePosition && !tablePosition.temp)) {
          // eslint-disable-next-line no-param-reassign
          table.position = oldTablePosition || tablePosition;
        } else {
          // eslint-disable-next-line no-param-reassign
          table.position = {
            x: 200 + offset * 20,
            y: 20 + offset * 20,
            temp: true,
          };
          offset += 1;
        }
      });
      modelList.value = {
        tables,
        relations,
      };
      // 更新currentModel信息，主要是同步ID
      const currentModelId = (modelInfo.value as any)?.id;
      if (currentModelId) {
        const currentInfo = tables.find((item) => item.id === currentModelId);
        if (modelInfo.value) {
          currentInfo.isBelongTo = (modelInfo.value as any).isBelongTo;
        }
        modelInfo.value = { ...currentInfo };
      }
      loading.value = false;

      nextTick(() => {
        erdRef.value && erdRef.value.dragTableEnd();
      });
    };
    const isMerged = ref(false);
    const mergedService = ref('');
    const serviceFullName = ref('');
    const numFlag = ref(0);
    const changeInfoOver = ref(false);
    const queryIsMerged = async () => {
      try {
        const { data } = await queryMergeStatus({
          serviceFullName: serviceFullName.value,
        });
        isMerged.value = data.isMerged;
        mergedService.value = data.mergedServiceShortName;
      } catch (e) {
        console.log(e);
      }
    };
    // 获取服务详情
    const fetchTimer = ref();
    const getServerInfo = async (useLoading = true) => {
      if (useLoading) {
        loading.value = true;
      }
      clearTimeout(fetchTimer.value);
      try {
        const { data } = await getServiceById({ id: currentServiceId.value });
        const { dependencies, ...info } = data;
        serviceFullName.value = data.name;
        if (numFlag.value === 0) {
          numFlag.value = 1;
          await queryIsMerged();
        }
        const dependencyList = dependencies.map((i: any) => [i.dependencyServiceName, i.dependencyServiceVersion]);
        serverInfo.value = {
          ...info,
          dependencies: dependencyList,
          serviceDependencies: dependencies,
        };
        editStatus.value = serverInfo.value.editStatus;
        !modelList.value.tables.length && initModelList();
        proxy.$forceUpdate();
        fetchTimer.value = setTimeout(() => getServerInfo(false), 5000);
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
    };

    getServerInfo();

    const tags: any[] = [];

    // 获取所有标签
    const getTags = async () => {
      const { data } = await getAllTags();
      tags.push(...(data || []));
    };

    getTags();

    const classifications: any[] = [];

    // 获取所有分类信息
    const getClassifications = async () => {
      const { data } = await getClassificationList();
      classifications.push(...(data || []));
    };

    getClassifications();

    // 获取所有字段类型
    const allTypes = ref([]);
    const initTypeOption = async () => {
      const { code, data } = await getDataTypesAll();
      if (code === 0) {
        allTypes.value = data;
      }
    };
    initTypeOption();

    // 服务状态
    const serverStatusInfo = ref({});

    watch(serverInfo, () => {
      const { initTimes, id } = serverInfo.value;
      const status = Number(serverInfo.value.status);
      currentServiceSourceAllowed.value = [1, 4].includes(serverInfo.value.serviceSource);
      const { init: initBtn, start: startBtn, stop: stopBtn, release: releaseBtn } = buttons.value;
      const isDisabled = status === 10 || status === 20 || status === 30;
      initBtn.disabled = isDisabled;
      startBtn.disabled = isDisabled;
      stopBtn.disabled = isDisabled;
      releaseBtn.disabled = isDisabled;

      initBtn.label = +initTimes === 0 ? '初始化' : '同步配置';
      switch (status) {
        case 0:
          startBtn.disabled = true;
          stopBtn.disabled = true;
          break;
        default:
          break;
      }

      if (status !== 21) {
        stopBtn.disabled = true;
        releaseBtn.disabled = true;
      }

      // 引用服务初始化以后禁用，并且没有同步配置功能
      if (isRefrenceService.value) {
        if (+initTimes !== 0) {
          initBtn.label = '初始化';
          initBtn.disabled = true;
        }
        // TODO. 这块数组索引的做法可读性太差，后面需要优化
        releaseBtn.disabled = true;
      }
      const statusmaps = computeStatusLabel(serverInfo.value.initTimes);
      serverStatusInfo.value = {
        label: (statusmaps as any)[status],
        color: (statusColor as any)[status],
      };
      if (status === 30) {
        updateServiceStatus([id]);
      }
    });

    watch(thenRefresh, () => {
      getServerInfo();
    });

    // 右侧组件名称
    const componentName = ref('');
    // 打开基本信息
    const openBaseInfo = () => {
      if (componentName.value === 'ServerBaseInfo') {
        componentName.value = '';
      } else {
        componentName.value = 'ServerBaseInfo';
      }
    };

    // 下侧组件名称
    const drawerName = ref('');

    // 打开接口配置
    const openPropertyInfo = () => {
      if (drawerName.value === 'ServerApiList') {
        isShowDownDrawer.value = false;
        drawerName.value = '';
      } else {
        isShowDownDrawer.value = true;
        drawerName.value = 'ServerApiList';
      }
    };

    const openDtoList = () => {
      if (drawerName.value === 'DtoList') {
        //  再次点击关闭
        isShowDownDrawer.value = false;
        drawerName.value = '';
      } else {
        isShowDownDrawer.value = true;
        drawerName.value = 'DtoList';
      }
    };
    // 打开服务配置
    const openConfigInfo = () => {
      if (drawerName.value === 'ServerConfigInfo') {
        isShowDownDrawer.value = false;
        drawerName.value = '';
      } else {
        isShowDownDrawer.value = true;
        drawerName.value = 'ServerConfigInfo';
      }
    };

    const openServerDependency = () => {
      if (drawerName.value === 'ServerDependency') {
        isShowDownDrawer.value = false;
        drawerName.value = '';
      } else {
        isShowDownDrawer.value = true;
        drawerName.value = 'ServerDependency';
      }
    };

    watch(isShowDownDrawer, (nn) => {
      if (!nn) {
        drawerName.value = '';
      }
    });

    // 打开gitlab页面
    const openGitlab = () => {
      window.open(serverInfo.value.sshHost + (serverInfo.value.deposit ? serverInfo.value.deposit : ''));
    };

    // 模型、关联详情数据
    provide('currentModel', modelInfo);
    provide('configs', { allTypes, tags, classifications });
    provide('afterRemove', () => {
      isShowDownDrawer.value = false;
      componentName.value = '';
      initModelList();
    });
    provide('afterUpdate', async () => {
      await initModelList();
    });
    // ---------- 是否为代理服务
    const isProxy = computed(() => serverInfo.value.appearance === SERVICE_APPEARANCE.PROXY);
    // 代理服务不显示模型，展示基本信息
    provide(isProxyService, isProxy);

    const pageLoading = ref(false);
    const modelLoading = ref(false);
    const modelSelected = async (model: any) => {
      const hasAuth = getShowBool('moduleSelect');
      if (model && hasAuth) {
        if (model.relationInfo) {
          componentName.value = 'RelationInfo';
          modelInfo.value = model.relationInfo;
          isShowDownDrawer.value = false;
        } else {
          modelLoading.value = true;
          isShowDownDrawer.value = true;
          // drawerName.value = 'ModelFieldForm';
          drawerName.value = 'ModelDetailForm';
          let copyModel: any = {};
          if (model?.external) {
            copyModel = { ...model };
          } else {
            const { data } = await getModelDetail(model.id);
            copyModel = { ...data };
          }
          modelInfo.value = {
            ...copyModel,
            fields: model.fields,
            ctrlDims: model.ctrlDims,
            indexList: model.indexList,
          };
          pageLoading.value = true;
          modelLoading.value = false;
        }
      }
    };

    const computedComponentData = computed(() =>
      componentName.value === 'ServerBaseInfo' ? serverInfo.value : modelInfo.value,
    );

    const reloadCom = ref(true);
    watch(modelInfo, () => {
      reloadCom.value = false;
      proxy.$nextTick(() => {
        reloadCom.value = true;
      });
      proxy.$forceUpdate();
    });

    // 切换服务

    const selectService = async (value: number) => {
      currentServiceId.value = value;
      let name = '';
      serverList.forEach((x: any) => {
        if (x.id === value) {
          name = x.name;
        }
      });
      componentName.value = '';
      isShowDownDrawer.value = false;
      let path = '';
      let query = null;

      if (envId && !name.includes('gw-')) {
        path = `/env/${env.toLocaleLowerCase()}/${envId}/service/${value}`;
        query = {
          env,
          envName,
        };
      } else if (envId && name.includes('gw-')) {
        path = `/env/${env.toLocaleLowerCase()}/${envId}/gwedit/${value}`;
        query = {
          env,
          envName,
        };
      } else if (name.includes('gw-')) {
        path = `/services/business/gwedit/${value}`;
        query = {
          detailName: name,
        };
      } else {
        path = `/services/business/edit/${value}`;
        query = {
          detailName: name,
        };
      }
      proxy.$router.replace({
        path,
        query,
      });
      if (!name.includes('gw-')) {
        setTimeout(() => {
          window.location.reload();
        });
      }
    };

    const logs = (res: any) => {
      console.log(res, 'this is log');
      return res;
    };

    const maskText = computed(() => {
      switch (serverInfo.value.status) {
        case 10:
          return '服务变更中, 请稍后...';
        case 20:
          return '服务启动中, 请稍后...';
        case 30:
          return '服务停止中, 请稍后...';
        default:
          return '';
      }
    });
    onBeforeUnmount(() => {
      clearTimeout(fetchTimer.value);
      removeEnvStorage();
    });

    const releaseRef: Ref<RefDialog | null> = ref(null);

    // 定义点击发版接口函数
    const continueRelese = async () => {
      if (!isProxy.value) {
        // 数据准备开始
        const { code, message } = await getPreDataStart(currentServiceId.value);
        if (code === 0) {
          (releaseRef.value as RefDialog).openDialog(currentServiceId.value);
        } else {
          msgTips('error', message);
        }
      } else {
        (releaseRef.value as RefDialog).openDialog(currentServiceId.value);
      }
      relDiavisible.value = false;
    };

    watch(releaseStart, async () => {
      // 服务发版前检查
      const { code, message, data } = await releaseCheck(currentServiceId.value);
      if (code === 0) {
        if (data.length > 0) {
          relDiavisible.value = true;
          releseList.value = data;
        } else if (!isProxy.value) {
          // 数据准备开始
          const { code, message } = await getPreDataStart(currentServiceId.value);
          if (code === 0) {
            (releaseRef.value as RefDialog).openDialog(currentServiceId.value);
          } else {
            msgTips('error', message);
          }
        } else {
          continueRelese();
        }
      } else {
        msgTips('error', message);
      }
    });
    const drawerReadonlyAuth = computed(() => !getShowBool('add'));
    watch(
      () => serverInfo.value.id,
      () => {
        forceUpdateBaseInfo();
      },
    );

    const isCoding = computed(() => userInfo.value?.gitRepository === 'CODING');

    // 启动服务
    const handleConfirmStart = async (domainData: any) => {
      const res = await startService({
        serviceId: currentServiceIdForData.value,
        branch: 'master',
        userId: '1',
        ...domainData,
      });
      thenRefresh.value = !thenRefresh.value;
      if (res.data) {
        getLog();
      }
    };

    // 模型被删除
    const deleteModel = () => {
      isShowDownDrawer.value = false;
    };

    // 当前服务是否已被合并
    if (serviceFullName.value) {
      sessionStorage.setItem('serviceDetail_serviceFullName', serviceFullName.value.toString());
    }

    return {
      isShowDownDrawer,
      computedHeight,
      currentServiceId,
      selectService,
      isOpenProperties,
      serverInfo,
      serverList,
      tags,
      classifications,
      buttons,
      serverStatusInfo,
      componentName,
      drawerName,
      modelLoading,
      openBaseInfo,
      openPropertyInfo,
      openConfigInfo,
      openGitlab,
      modelList,
      initModelList,
      erdLoading,
      modelSelected,
      modelInfo,
      releaseDialogVisible,
      computedComponentData,
      logs,
      statusMap,
      maskText,
      userProjectList,
      getShowBool,
      pageLoading,
      reloadCom,
      getServerInfo,
      loading,
      closeReleaseDialog,
      releaseRef,
      isRefrenceService,
      openDtoList,
      drawerReadonlyAuth,
      isProxy,
      changeInfoOver,
      renderKey,
      isCoding,
      logDialogRef,
      handleConfirmStart,
      domainRef,
      erdRef,
      envName,
      env,
      openServerDependency,
      deleteModel,
      isMerged,
      mergedService,
      relDiavisible,
      releseList,
      continueRelese,
    };
  },
};
