
import { computed, defineComponent, provide } from '@vue/runtime-core';
import { computeStatusLabel, statusColor } from '@/views/service-management/business-service/utils/service-status-map';
import {
  useGetServiceDependencyList,
  useServiceNameSearch,
  useOperateServiceDependency,
  useCalcMethods,
} from './dependency';
import DependencyDialog from './DependencyDialog.vue';
import { getShowBool } from '@/utils/permission-show-module';

export default defineComponent({
  name: 'server-dependency',
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    source: {
      type: Number,
      required: true,
    },
  },
  components: {
    DependencyDialog,
  },
  emits: ['back'],
  setup(props) {
    const { loading, dependencyList, dependencyListAll, fetchDependencyList } = useGetServiceDependencyList(
      props.id,
      props.name,
    );
    const { keyword, filter, emptyKeyword } = useServiceNameSearch(dependencyList, dependencyListAll);
    const {
      status,
      dialogVisible,
      severListCanAdd,
      currentDependency,
      selectDependencies,
      versions,
      addDependencyHandler,
      editDependencyHandler,
      delDependencyHandler,
      changeSelectDependenciesHandler,
      close,
      save,
    } = useOperateServiceDependency(props.id, props.name, dependencyListAll, fetchDependencyList, emptyKeyword);
    const { tableRowClassName, versionTip, canEdit } = useCalcMethods();
    provide('dependencyOptions', {
      status,
      versions,
      dialogVisible,
      currentDependency,
      severListCanAdd,
      selectDependencies,
      save,
      close,
      changeSelectDependenciesHandler,
    });
    const showOperation = computed(() => {
      const forbidden = [2];
      return !forbidden.includes(props.source);
    });
    const hasCreateAuth = () => getShowBool('addDependency') && showOperation.value && !dependencyListAll.value.length;
    return {
      showOperation,
      loading,
      computeStatusLabel,
      statusColor,
      dependencyList,
      keyword,
      filter,
      addDependencyHandler,
      editDependencyHandler,
      delDependencyHandler,
      changeSelectDependenciesHandler,
      tableRowClassName,
      versionTip,
      canEdit,
      getShowBool,
      hasCreateAuth,
    };
  },
});
