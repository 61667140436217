import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ee5c7b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "drawer-content" }
const _hoisted_2 = { class: "drawer-content__main" }
const _hoisted_3 = { class: "table-header" }
const _hoisted_4 = { class: "table-header__cell" }
const _hoisted_5 = {
  key: 0,
  class: "table-header__cell"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditModel = _resolveComponent("EditModel")!
  const _component_model_field_form = _resolveComponent("model-field-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_model_index_form = _resolveComponent("model-index-form")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_EditModel)
        ]),
        (_ctx.currentModel?.targetServiceName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, " 来源服务： " + _toDisplayString(_ctx.currentModel?.targetServiceName), 1))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_el_tabs, {
        modelValue: _ctx.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
        class: "table-body",
        "before-leave": _ctx.handleClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, {
            name: "fields",
            label: "数据字段"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_model_field_form, {
                ref: "fieldsRef",
                modelLoading: _ctx.modelLoading,
                editStatus: _ctx.editStatus,
                onBack: _ctx.closeDrawer
              }, null, 8, ["modelLoading", "editStatus", "onBack"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, {
            name: "index",
            label: "索引"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_model_index_form, {
                ref: "indexRef",
                editStatus: _ctx.editStatus,
                modelLoading: _ctx.modelLoading,
                onBack: _ctx.closeDrawer
              }, null, 8, ["editStatus", "modelLoading", "onBack"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "before-leave"])
    ])
  ]))
}