
import { defineComponent, ref } from 'vue';
import ImportModelModal from '@/views/service-management/business-service/components/import-models/Index.vue';

export default defineComponent({
  name: 'AddModel',
  props: {
    editStatus: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ImportModelModal,
  },
  setup() {
    const importModelRef = ref<InstanceType<typeof ImportModelModal>>();
    const showDialog = () => {
      // eslint-disable-next-line no-unused-expressions
      importModelRef.value?.openDialog();
    };
    return {
      importModelRef,
      showDialog,
    };
  },
});
