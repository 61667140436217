
import { defineComponent, ref, inject, watch, onMounted } from 'vue';
import { removeRelation, updateRelation } from '@/api/schema/model';
import { isRefrence } from '../utils/permisson';
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: 'RelationInfo',
  props: {
    data: {
      require: true,
      type: Object,
    },
  },
  setup(props: any) {
    const serviceId = inject('serviceId');
    const afterUpdate = inject('afterUpdate') as Function;
    const afterRemove = inject('afterRemove') as Function;
    const isRefrenceService = inject(isRefrence);
    const editStatusQuery = ref(0 as any);

    const formData = ref(props.data);
    console.log(props.data, 'props.data');

    const remove = async () => {
      const { code } = await removeRelation({
        ids: [formData.value.relationId],
      });
      if (code === 0) {
        ElMessage.success('关联类型删除成功');
        afterRemove();
      }
    };
    const relationChange = async () => {
      if (isRefrenceService?.value) {
        return;
      }
      const { code } = await updateRelation(formData.value.relationId, {
        fromModelId: formData.value.fromModelId,
        toModelId: formData.value.toModelId,
        relationType: formData.value.relationType,
        serviceId,
      });
      if (code === 0) {
        ElMessage.success('关联类型更新成功');
        afterUpdate();
      }
    };
    const showOrNot = ref(true);
    watch(
      () => props.data,
      (val: any) => {
        formData.value = val;
      },
    );
    onMounted(() => {
      const editStatus: any = inject('editStatus');
      editStatusQuery.value = parseInt(editStatus.value);
    });
    return {
      formData,
      relationChange,
      remove,
      showOrNot,
      isRefrenceService,
      editStatusQuery,
    };
  },
});
