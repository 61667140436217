
import { parseJavaEntity } from '@/api/schema/model';
import { JavaEntityType } from '@/components/data-model/erd/types';
import { ElForm } from 'element-plus';
import _ from 'lodash';
import { defineComponent, reactive, ref } from 'vue';

export default defineComponent({
  name: 'BaseForm',
  setup() {
    const form = reactive({
      code: '',
    });
    const rules = {
      code: [
        {
          required: true,
          message: '请输入Java实体类',
          trigger: 'blur',
        },
        {
          maxlength: 10240,
          message: '不能超过10240个字符',
          trigger: 'blur',
        },
      ],
    };
    const formRef = ref<InstanceType<typeof ElForm>>();
    const handleSubmit = async () => {
      const valid = await formRef.value?.validate();
      if (!valid) {
        throw new Error('校验失败');
      }

      const { code, data } = await parseJavaEntity({
        code: form.code,
        type: JavaEntityType.underscope,
      });

      if (code !== 0) {
        throw new Error('解析失败');
      }
      const modelData = {
        fields: _.uniqBy(data.structs.map((e) => e.fields).flat(), 'name'),
        className: data.structs[0].className,
      };
      modelData.fields = modelData.fields.map((item: any) => ({
        ...item,
        dbTypeLength: item?.length,
        dbTypePrecision: item?.precision,
      }));
      console.log('模型解析', modelData.fields);
      return modelData;
    };
    return {
      form,
      rules,
      formRef,
      handleSubmit,
    };
  },
});
