/*
 * @Date: 2022-04-01 18:44:59
 * @LastEditTime: 2022-04-15 23:45:12
 * @LastEditors: rubiwang
 * @Description:
 * @FilePath: /management-home/src/views/service-management/business-service/components/service-dependency/dependency.ts
 */
import { ref } from '@vue/reactivity';
import { onBeforeMount, inject } from '@vue/runtime-core';
import { getDependencyList, updateDependency, deleteDependency, addDependency } from '@/api/servers/index';
import {
  getServiceSourceName,
  getServiceDependencies,
  dependenciesList as serverList,
  getServiceVersionType,
} from '../../utils/service-data-utils';
import { ElMessageBox, ElMessage } from 'element-plus';
import { externalModels } from '@/views/service-management/business-service/utils/service-detail-data';

type DependencyModel = {
  name: string;
  description: string;
  classification: string;
  tag: string;
  createUser: string;
  owners: any[];
  serviceSource: number;
  dependencyServiceName: string;
  dependencyServiceVersion: string;
  dependencyServiceVersionType: number;
  service: any;
};

type OwnerModel = {
  id: number;
  displayName: string;
};

type SeverCanAddItem = {
  value: string;
  label: string;
  versionType: number;
  disabled: boolean;
};

type SeverCanAdd = {
  label: string;
  value: string;
  children: SeverCanAddItem[];
};

const projectId = localStorage.getItem('projectId');
// 2: 引用 3: 克隆
const needToCompare = [2, 3];

export const useGetServiceDependencyList = (id: number, name?: string) => {
  const loading = ref(false);
  const dependencyList = ref([]);
  const dependencyListAll = ref([]);
  const { classifications, tags } = inject('configs') as any;
  const classificationMap: any = {};
  const tagMap: any = {};
  classifications.forEach((item: any) => (classificationMap[item.id] = item.name));
  tags.forEach((item: any) => (tagMap[item.id] = item.name));

  const fetchDependencyList = async () => {
    loading.value = true;
    try {
      const { data, code } = await getDependencyList({ id });
      loading.value = false;
      if (code === 0) {
        if (data.rows?.length) {
          data.rows.forEach((dependency: DependencyModel, index: number) => {
            const { service = {} } = dependency;
            const names = String(service.name || '').split('.');
            const nameEn = names[names.length - 1];
            if (dependency.service?.isDelete || dependency.service?.projectId !== Number(projectId)) {
              Object.assign(dependency, {
                index,
                nameEn,
                description: service.description,
              });
            } else {
              const { owners = [] } = dependency;
              const arr = service.classification ? service.classification.split(',') : [];
              const classification = arr
                .map((x: any) => classificationMap[x])
                .filter((x: any) => x)
                .join(',');
              const tagarr = service.tag ? service.tag.split(',') : [];
              const tag = tagarr
                .map((x: any) => tagMap[x])
                .filter((x: any) => x)
                .join(',');
              const sortable = owners?.sort((a: any, b: any) => a.id - b.id) || [];
              const createUser = sortable
                .map((x: OwnerModel) => x.displayName)
                .filter((x: any) => x)
                .join(',');
              const source = getServiceSourceName(service?.serviceSource);
              Object.assign(dependency, {
                index,
                nameEn,
                classification,
                tag,
                createUser,
                description: service.description,
                appearance: service.appearance,
                delState: service.delState,
                status: service.status,
                source,
              });
              if (!needToCompare.includes(service?.serviceSource)) {
                Object.assign(dependency, {
                  versionFlag: 0,
                });
              }
            }
          });
        }
        dependencyList.value = data.rows;
        dependencyListAll.value = data.rows;
      }
    } catch (error) {
      loading.value = false;
      console.log(error);
    }
  };
  onBeforeMount(fetchDependencyList);
  getServiceDependencies(id, name);
  return {
    loading,
    dependencyList,
    dependencyListAll,
    fetchDependencyList,
  };
};

export const useServiceNameSearch = (dependencyList: any, dependencyListAll: any) => {
  const keyword = ref('');
  const filter = () => {
    // eslint-disable-next-line no-param-reassign
    dependencyList.value = keyword
      ? dependencyListAll.value.filter(
          (item: any) => item.dependencyServiceName.includes(keyword.value) || item.description.includes(keyword.value),
        )
      : dependencyListAll.value;
  };
  const emptyKeyword = () => {
    keyword.value = '';
  };
  return {
    keyword,
    filter,
    emptyKeyword,
  };
};

export const useOperateServiceDependency = (
  id: number,
  name: string,
  addedDependencies: any,
  fetchDependencyList: any,
  emptyKeyword: any,
) => {
  let insertIndex = 0;
  const status = ref('add');
  const dialogVisible = ref(false);
  const selectDependencies = ref([]);
  const severListCanAdd = ref([]);
  const currentDependency = ref();
  const versions = ref([]);

  const addDependencyHandler = (index: number) => {
    status.value = 'add';
    insertIndex = index;
    const temp: any = serverList.value.filter(
      (list: SeverCanAdd) =>
        !addedDependencies.value.find(({ dependencyServiceName }: any) => dependencyServiceName === list.value),
    );

    severListCanAdd.value = temp;
    dialogVisible.value = true;
  };
  const editDependencyHandler = (row: any) => {
    currentDependency.value = JSON.parse(JSON.stringify(row));
    status.value = 'edit';
    dialogVisible.value = true;
    const dependency = serverList.value.find(({ value }: SeverCanAdd) => value === row.dependencyServiceName);
    versions.value = dependency?.children || [];
  };

  const delDependencyHandler = async (row: any) => {
    const externalArr = externalModels.value.map((item: any) => item.targetServiceName);
    if (externalArr.includes(row.dependencyServiceName)) {
      return ElMessage.error('此服务被当前服务依赖，且已导入数据模型，请先移除外部关联模型。');
    }
    ElMessageBox.confirm(`确认删除服务依赖 ${row.nameEn} : ${row.dependencyServiceVersion}?`, '提示', {
      type: 'warning',
      showClose: false,
    }).then(() => {
      deleteDependency(id, row).then(() => {
        emptyKeyword();
        fetchDependencyList(id, name);
      });
    });
  };

  const changeSelectDependenciesHandler = (nodes: any) => {
    if (status.value === 'add') {
      const checkNode: any = {};
      for (const node of nodes) {
        checkNode[node[0]] = node;
      }
      selectDependencies.value = Object.values(checkNode);
    } else {
      const selected: any = versions.value.find(({ value }: any) => value === nodes);
      currentDependency.value.dependencyServiceVersionType = selected.versionType;
    }
  };

  const close = () => {
    dialogVisible.value = false;
    selectDependencies.value = [];
  };

  const save = async () => {
    if (status.value === 'add') {
      const dependenciestemp = addedDependencies.value.map(
        ({ dependencyServiceName, dependencyServiceVersion, dependencyServiceVersionType }: DependencyModel) => ({
          dependencyServiceName,
          dependencyServiceVersion,
          dependencyServiceVersionType,
        }),
      );
      const addDependenciesTemp = selectDependencies.value.map((dependency: string[]) => {
        const [dependencyServiceName, dependencyServiceVersion] = dependency;
        return {
          dependencyServiceName,
          dependencyServiceVersion,
          dependencyServiceVersionType: getServiceVersionType(dependencyServiceName, dependencyServiceVersion),
        };
      });
      dependenciestemp.splice(insertIndex + 1, 0, ...addDependenciesTemp);
      await addDependency({
        serviceId: id,
        dependencies: dependenciestemp,
      });
    } else {
      await updateDependency(id, currentDependency.value);
    }
    emptyKeyword();
    fetchDependencyList(id, name);
    close();
  };

  return {
    status,
    dialogVisible,
    severListCanAdd,
    currentDependency,
    selectDependencies,
    versions,
    addDependencyHandler,
    editDependencyHandler,
    delDependencyHandler,
    changeSelectDependenciesHandler,
    close,
    save,
  };
};

export const useCalcMethods = () => {
  const tableRowClassName = ({ row }: any) => {
    if (row.service?.isDelete || row.service?.projectId !== Number(projectId)) {
      return 'error-row';
    }
    const versionCheckClassNames = ['', 'too-high', 'too-low'];
    return versionCheckClassNames[row.versionFlag];
  };
  const versionTip = ({ row }: any) => {
    if (!row.service?.isDelete && row.service?.projectId === Number(projectId) && row.versionFlag === 2) {
      return true;
    }
    return false;
  };
  const canEdit = ({ row }: any) => {
    if (row.service?.isDelete || row.service?.projectId !== Number(projectId)) {
      return false;
    }
    return true;
  };
  return {
    tableRowClassName,
    versionTip,
    canEdit,
  };
};
