
/* eslint-disable no-param-reassign */
import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  reactive,
  Ref,
  ref,
  toRefs,
  watch,
  watchEffect,
} from 'vue';
import { getDataTypesAll } from '@/api/settings/data-types';
import {
  getCtrlDimByFieldId,
  getCtrlDim,
  getCustomDataType,
  updateCtrlDim,
  updateFields,
  getServiceModelList,
} from '@/api/schema/model';
// import { getAllServiceList } from "@/api/servers"; // getAllServicesModelsList
import { getShowBool } from '@/utils/permission-show-module';
import _ from 'lodash/fp';
import { currentServiceSourceAllowed, isRefrence } from '../utils/permisson';
import { ElMessage, ElMessageBox } from 'element-plus';
import { alloverEdit } from '@/layout/messageCenter/routerRef';
import DefaultValue from '@/views/service-management/business-service/components/DefaultValue.vue';
import useEditModel from './useEditModel';
import ServicesConfig from '@/utils/services-filter-config';
import StandardField from './StandardField.vue';
import { getServiceShowName } from '@/views/application-module/utils/utils';
import DataTypePopover from '@/views/service-management/business-service/components/DataTypePopover.vue';
import { genId } from '@/utils/util';
import { DEFAULT_VALUE_TYPE } from '@/views/service-management/business-service/components/defaultValueType';
import useTags from '../utils/service-baseinfo-tag';

const SYSTEM_ID_NAME = '雪花ID';

interface StateInterface {
  createDialogVisible: boolean;
  ischange: number;
  appInfo: {
    index: number;
    isPreDimen: boolean;
    id: number;
    name: string;
    isNull: boolean;
    relationId: number;
  };
  onjFields: {
    fieldId: number;
    fieldName: string;
    description: string;
  };
  nullMessage: string;
  isSerciveFilter: boolean;
  httpLoading: boolean;
}

export default defineComponent({
  name: 'ColumnForm',
  components: {
    DataTypePopover,
    DefaultValue,
    StandardField,
  },
  props: {
    modelLoading: {
      type: Boolean,
      default: true,
    },
    editStatus: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {
    // 组件实例
    const instance = getCurrentInstance();
    const currentModel = inject('currentModel') as Ref<any>;
    const serverInfo = inject('serverInfo') as Ref<any>;
    const serverList = inject('serverList') as Array<any>;
    const serviceId = inject('serviceId') as number;
    const { tags } = inject('configs') as any;
    const afterUpdate = inject('afterUpdate') as Function;
    const isRefrenceService = inject(isRefrence);
    const isInEdit = ref(false);
    const fields: Ref<Array<any>> = ref([]);
    const { tagNames } = useTags(serverInfo.value.tag, tags);

    // const serverListAll: Ref<Array<any>> = ref([]);
    const optionCtrlDim: Ref<Array<any>> = ref([]);
    const modelCtrlDim: Ref<Array<any>> = ref([]);
    const state: StateInterface = reactive({
      createDialogVisible: false,
      ischange: 0,
      appInfo: {
        index: 0,
        isPreDimen: false,
        id: -1,
        name: '',
        isNull: false,
        relationId: 0,
      },
      onjFields: {
        fieldId: 0,
        fieldName: '',
        description: '',
      },
      nullMessage: '权限名称不能为空',
      isSerciveFilter: computed(() => {
        const shortName = serverInfo.value.name.split('.')[3];
        return ServicesConfig.includes(shortName);
      }),
      httpLoading: false,
    });

    // 获取当前服务所依赖的服务
    const serviceDependencies = serverInfo.value.dependencies.map((item: string[]) => getServiceShowName(item[0]));

    const propsCtrlDim = {
      lazy: true,
      async lazyLoad(node: any, resolve: any) {
        const { level, data } = node;
        let rows = [];
        if (level === 0) {
          rows = serverList.filter(
            (item: any) =>
              !serviceDependencies.some((dependency: string) => item.name.includes(dependency)) &&
              item.appearance !== 3,
          );
        } else if (level === 1) {
          const res = await getServiceModelList({
            serviceId: data.value,
          });
          rows = res.data.models;
        } else {
          rows = data.dataChild;
        }

        let nodes = '';
        if (!data?.children) {
          nodes = rows?.map((item: any) => ({
            value: item.id,
            label: item.name.split('.')[item.name.split('.').length - 1],
            dataChild: level === 1 ? item.fields : [],
            leaf: level >= 2,
          }));
        }
        resolve(nodes);
      },
    };

    const handleDataTypeChange = (row: any) => {
      row.defaultValue = '';
      row.defaultValueType = 0;
    };

    // 根据字段id获取权限维度数据
    const getReleaseData = async (id: number) => {
      const res = await getCtrlDimByFieldId(id);
      return res;
    };

    // 获取所有服务
    // const getServiceAllList = async () => {
    //   try {
    //     const { data } = await getAllServiceList({ all: true });
    //     serverListAll.value = data.rows;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    // getServiceAllList();

    const servicesId = ref(null);
    const servicesModel: Ref<Array<any>> = ref([]);
    // 根据模型id获取权限数据
    const getCtrlDimByModelId = async (id: number) => {
      const { data } = await getCtrlDim(id);
      await data.forEach((item: any) => {
        if (item.relationId !== 0) {
          getReleaseData(item.relationId).then((res: any) => {
            // console.log(res);
            item.relationData = res.data;

            if (servicesId.value !== res.data.serviceId) {
              servicesId.value = res.data.serviceId;
              getServiceModelList({
                serviceId: res.data.serviceId,
              }).then((res: any) => {
                item.relationModel = res.data.models;
                servicesModel.value = res.data.models;
                state.ischange = Math.floor(Math.random() * 1000000000);
              });
            } else {
              item.relationModel = servicesModel.value;
            }
            state.ischange = Math.floor(Math.random() * 1000000000);
          });
        }
      });
      modelCtrlDim.value = data;
    };

    watch(
      () => currentModel.value,
      () => {
        getCtrlDimByModelId(currentModel.value?.id);
        // ctrlDimWatck();
      },
    );

    const getServerListAll = () => {
      if (optionCtrlDim.value.length === 0) {
        const nodes = serverList.filter(
          (item: any) =>
            !serviceDependencies.some((dependency: string) => item.name.includes(dependency)) && item.appearance !== 3,
        );

        optionCtrlDim.value = nodes?.map((item: any) => ({
          value: item.id,
          label: item.name.split('.')[item.name.split('.').length - 1],
        }));
      }
    };
    getServerListAll();

    // 构造modelChildren
    const mapModel = (modelItem: any) => ({
      value: modelItem.id,
      label: modelItem.name,
      children: modelItem.fields?.map((v: any) => ({
        value: v.id,
        label: v.columnName,
        leaf: true,
      })),
    });

    // 处理字段信息，加上从属关联数据
    const getFieldData = (field: any) => {
      field.forEach((item: any) => {
        if (modelCtrlDim.value.length > 0 && item.id) {
          const ctrlDim = modelCtrlDim.value?.find((i: any) => item.id === i.fieldId);

          item.ctrlDims = ctrlDim;
          if (ctrlDim?.relationId !== 0 && ctrlDim?.relationData) {
            const { serviceId, modelId, fieldId, serviceName, modelName, fieldName } = ctrlDim.relationData;

            optionCtrlDim.value.forEach((item: any) => {
              if (serviceId === item.value) {
                item.children = ctrlDim.relationModel?.map(mapModel);
              }
            });

            item.dependence = [serviceId, modelId, fieldId];
            item.dependenceName = `${serviceName}.${modelName}.${fieldName}`;
          } else {
            item.dependence = [];
            item.dependenceName = '';
          }
          // console.log(item);
        }
      });
      return field;
    };

    watch(
      () => state.ischange,
      () => {
        getFieldData(fields.value);
      },
    );

    watchEffect(() => {
      const field = _.cloneDeep(currentModel.value?.fields || []);
      fields.value = getFieldData(field);
    });

    const getCtrlDimById = async (id: number, index: number) => {
      const ctrlDim = await getCtrlDimByFieldId(id);
      fields.value[index].ctrlDims = ctrlDim.data;
    };

    // 权限维度选择事件
    const changeCtrlDim = async (index: number) => {
      const { dependence } = fields.value[index];
      const relationId = dependence === null ? 0 : dependence[2];
      const field = fields.value[index].ctrlDims;
      let relationDimension = '';
      if (relationId !== 0) {
        const releaseData = await getReleaseData(relationId);
        const { serviceName, modelName, fieldName } = releaseData.data;
        relationDimension = `${serviceName}#${modelName}#${fieldName}`;
      }
      const obj = {
        name: field.name,
        serviceId: field.serviceId,
        serviceName: field.serviceName,
        modelId: field.modelId,
        modelName: field.modelName,
        fieldId: field.fieldId,
        fieldName: field.fieldName,
        description: field.description,
        relationId,
        relationDimension,
      };
      const { id } = fields.value[index];
      const { code } = await updateCtrlDim(JSON.parse(JSON.stringify(obj)), id);
      fields.value[index].dependenceName = code === 0 ? '' : relationDimension.replaceAll('#', '.');
      getCtrlDimById(id, index);
    };

    const { onInputName, validateFields } = useEditModel();

    // 选择非空选项时，若默认值为NULL需要清空
    const handleIsNotNull = (val: boolean, row: any) => {
      if (val && row.defaultValueType === DEFAULT_VALUE_TYPE.NULL) {
        ElMessage.warning('当前默认值不符合要求，请重新选择');
        row.defaultValue = '';
        row.defaultValueType = DEFAULT_VALUE_TYPE.NONE;
      }
    };

    // 数据类型相关---预置数据类型接口
    const presetTypes: Ref<Array<any>> = ref([]);
    const getPresetOptions = async () => {
      try {
        const { code, data } = await getDataTypesAll();
        if (code === 0) {
          presetTypes.value = data;
        }
      } catch (e) {
        console.log(e);
      }
    };
    getPresetOptions();

    // dbTypes
    const dbMap = new Map();
    const dbTypes = ref([]);
    const getAllDbTypes = async () => {
      try {
        const { data, code } = await getCustomDataType();
        if (code === 0) {
          dbTypes.value = data;
          data.map((item: any) => dbMap.set(item.dbType, item));
        }
      } catch (e) {
        console.log(e);
      }
    };
    getAllDbTypes();

    const add = (index: number) => {
      fields.value.splice(index + 1, 0, {
        name: '',
        columnName: '',
        description: '',
        type: '',
        id: `_${genId()}`,
        isUnique: false,
        notNull: false,
        isIndex: false,
        defaultValue: '',
        defaultValueType: 0,
        javaType: '',
        dbType: '',
        dbTypeName: '',
        dbTypeLength: 0,
        dbTypePrecision: 0,
        dtoType: '',
        mode: 'add',
        dependence: 0,
      });
    };

    const remove = async (index: number) => {
      const answer = await ElMessageBox.confirm('当前操作可能会导致数据库部分数据被删除，请确认是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      if (!answer) return;
      fields.value.splice(index, 1);
    };

    const back = () => {
      context.emit('back');
    };
    const submitLoading = ref(false);
    const save = async () => {
      let msg: any = '';
      try {
        await validateFields(fields.value);
      } catch (e) {
        msg = (e as any).errors[0].message;
      }

      if (msg) {
        (instance as any).proxy.$message({
          type: 'error',
          message: msg,
        });
        return;
      }
      const inputData = _.flow(
        _.map(
          _.pick([
            'id',
            'name',
            'description',
            'typeId',
            'notNull',
            'isUnique',
            'isSystem',
            'isIndex',
            'isStandard',
            'columnName',
            'isParticipleSupport',
            'isPinyinSupport',
            'defaultValue',
            'defaultValueType',
            'javaType',
            'dbType',
            'dbTypeName',
            'dbTypeLength',
            'dbTypePrecision',
            'dtoType',
            'dependence',
          ]),
        ),
      )(fields.value);
      if (inputData.length === 0) {
        ElMessage.error('请至少添加一个属性');
        return;
      }

      inputData.forEach((x: any) => {
        if (!x.notNull) {
          // eslint-disable-next-line no-param-reassign
          x.notNull = false;
        }
        const type: any = dbTypes.value.find((item: any) => item.dbType === x.dbType);
        x.dtoType = type?.dtoType;
        const reg = /^_.*/;
        if (reg.test(x.id)) {
          delete x.id;
        }
      });

      if (tagNames.value.includes('saas服务')) {
        const isHaveTenantId = inputData.find((field: any) => field.name === 'tenantIdBuiltIn');

        if (!isHaveTenantId) {
          inputData.splice(inputData.length, 0, {
            name: 'tenantIdBuiltIn',
            columnName: 'tenant_id_built_in',
            description: '租户id',
            isUnique: false,
            notNull: false,
            isIndex: false,
            defaultValue: '10000',
            defaultValueType: 1,
            javaType: 'String',
            dbType: 'VARCHAR',
            dbTypeName: '文本(20)',
            dbTypeLength: 20,
            dbTypePrecision: null,
            dtoType: 'String',
            dependence: 0,
          });
        }
      }
      try {
        for (let i = 0; i < inputData.length; i++) {
          for (let j = i + 1; j < inputData.length; j++) {
            if (inputData[i].description === inputData[j].description) {
              ElMessage.info('属性描述不能重复');
              return false;
            }
          }
          if (inputData[i].name.toUpperCase() == currentModel.value.name.toUpperCase()) {
            ElMessage.error(`属性名称 ${inputData[i].name} 与模型名称 ${currentModel.value.name} 重复，请重新输入`);
            return false;
          }
        }
        submitLoading.value = true;
        const { code } = await updateFields(currentModel.value?.id, {
          serviceId,
          fields: inputData,
        });
        if (code === 0) {
          currentModel.value.fields = fields.value;
          ElMessage.success('保存成功');
          isInEdit.value = false;
          submitLoading.value = false;
          afterUpdate();
        }
      } catch (e) {
        submitLoading.value = false;
        console.log(e);
      }
    };

    const isFieldDisabled = (scope: any) =>
      scope.row.isSystem ||
      scope.row.isStandard ||
      (tagNames.value.includes('saas服务') && scope.row.name === 'tenantIdBuiltIn');
    // 判断是否为ref字段
    const isRefField = (row: any) => {
      const reg = /^ref.*/;
      return reg.test(row.name);
    };

    const isReadonly = computed(() => !isInEdit.value);
    watch(currentModel, () => {
      if (currentModel?.value?.external) {
        isInEdit.value = false;
      }
    });
    const toggleIsInEdit = (value: boolean) => {
      isInEdit.value = value;
      alloverEdit.value = value;
    };
    const beforeClose = () => {
      isInEdit.value = false;
      // fields.value = fields.value.filter((item) => item.id);
      afterUpdate();
      const field = _.cloneDeep(currentModel.value?.fields || []);
      fields.value = getFieldData(field);
      // fields.value = _.cloneDeep(currentModel.value?.fields || []);
    };
    const handleCancel = () => {
      if (isInEdit.value) {
        ElMessageBox.confirm(`编辑中的数据尚未保存，是否退出?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          beforeClose();
        });
      } else {
        beforeClose();
        context.emit('back');
      }
    };

    const defaultValueColor = {
      2: 'red',
      3: 'green',
    };

    const handleFieldsListChange = (newFieldsList: any[]) => {
      fields.value = [...newFieldsList];
    };

    const handleChangeDataType = (row: any) => {
      const index = fields.value.findIndex((item: any) => item.id === row.id);
      const newRow = { ...fields.value[index] };
      ['javaType', 'dbType', 'dbTypeName', 'dbTypeLength', 'dbTypePrecision'].forEach((key: string) => {
        newRow[key] = row[key];
      });
      // 数据类型改变时，有默认值不符合当前类型的情况清空
      const currentDbType = dbMap.get(row.dbType);
      if (
        (!currentDbType.expressionDefaultItems && row.defaultValueType === DEFAULT_VALUE_TYPE.EXPRESSION) ||
        (!currentDbType.customAvailable && row.defaultValueType === DEFAULT_VALUE_TYPE.CUSTOM)
      ) {
        ElMessage.warning('当前默认值不符合要求，请重新选择');
        newRow.defaultValue = '';
        newRow.defaultValueType = DEFAULT_VALUE_TYPE.NONE;
      }
      fields.value.splice(index, 1, newRow);
    };

    const showFields = computed(() => [...fields.value].sort((a, b) => a.isStandard - b.isStandard));

    const handelChange = async () => {
      if (isInEdit.value) {
        const answer = await ElMessageBox.confirm('编辑中的数据尚未保存，请先保存数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        if (!answer) return;
        save();
        return true;
      }
      return true;
    };

    return {
      fields,
      changeCtrlDim,
      add,
      remove,
      presetTypes,
      back,
      save,
      isFieldDisabled,
      getShowBool,
      isRefrenceService,
      isInEdit,
      isReadonly,
      toggleIsInEdit,
      handleCancel,
      defaultValueColor,
      onInputName,
      optionCtrlDim,
      ...toRefs(state),
      SYSTEM_ID_NAME,
      submitLoading,
      handleDataTypeChange,
      currentServiceSourceAllowed,
      handleFieldsListChange,
      showFields,
      currentModel,
      handelChange,
      handleChangeDataType,
      dbTypes,
      handleIsNotNull,
      isRefField,
      propsCtrlDim,
      tagNames,
    };
  },
});
