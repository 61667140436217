
import { computed, defineComponent, inject, reactive, Ref, ref, watch, watchEffect } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getShowBool } from '@/utils/permission-show-module';
import _ from 'lodash/fp';
import { alloverEdit } from '@/layout/messageCenter/routerRef';
import { getIndexFields, updateModelIndex } from '@/api/schema/model';
import { currentServiceSourceAllowed, isRefrence } from '../utils/permisson';

// 索引类型
export enum indexTypeEnum {
  '普通索引' = 1,
  '唯一索引',
}

export default defineComponent({
  name: 'IndexForm',
  components: {},
  props: {
    modelLoading: {
      type: Boolean,
      default: true,
    },
    editStatus: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {
    // 组件实例
    const currentModel = inject('currentModel') as Ref<any>;
    const afterUpdate = inject('afterUpdate') as Function;
    const isRefrenceService = inject(isRefrence);
    const serviceId = inject('serviceId') as number;
    const isInEdit = ref(false);
    const indexList: Ref<Array<any>> = ref([]);
    const formRef: Ref<any> = ref(null);
    const fields: Ref<Array<any>> = ref([]);
    const tableForm = reactive({
      dataList: computed(() => [...indexList.value]),
      rules: {
        fields: [{ required: true, message: '请选索引字段', trigger: 'change' }],
      },
    });
    const indexTypeOptions = [
      {
        value: 1,
        label: '普通索引',
      },
      {
        value: 2,
        label: '唯一索引',
      },
    ];
    const changeFields = (data: any[]) => data.join(',');
    watchEffect(() => {
      const data = _.cloneDeep(currentModel.value?.indexList || []);
      indexList.value = data.map((item: any) => ({
        ...item,
        indexType: indexTypeEnum[item.idxType],
        indexFields: changeFields(item.fields),
      }));
    });

    const add = (index = 0) => {
      indexList.value.splice(index + 1, 0, {
        fields: [],
        idxType: indexTypeEnum['普通索引'],
        description: '',
        mode: 'add',
      });
    };
    const remove = async (index: number) => {
      const answer = await ElMessageBox.confirm('请确认是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      if (!answer) return;
      indexList.value.splice(index, 1);
    };

    const submitLoading = ref(false);
    const save = async () => {
      try {
        await formRef.value.validate(async (valid: boolean, fields: any) => {
          if (valid) {
            const inputData = indexList.value.map((item: any) => {
              const { id, fields, idxType, description } = item;
              return {
                id,
                fields,
                idxType,
                description,
              };
            });
            submitLoading.value = true;
            const { code } = await updateModelIndex(currentModel.value?.id, {
              serviceId,
              indexList: inputData,
            });
            if (code === 0) {
              currentModel.value.indexList = indexList.value;
              ElMessage.success('保存成功');
              isInEdit.value = false;
              submitLoading.value = false;
              afterUpdate();
            }
          } else {
            console.log('error submit!', fields);
          }
        });
      } catch (error) {
        submitLoading.value = false;
        console.log(error);
      }
    };

    // 禁止编辑指定列的数据
    const disabledEdit: any = ['fields', 'idxType', 'description'];
    const isIndexDisabled = (scope: any, dataType: any) => disabledEdit.includes(dataType) && !scope.row.mode;

    const isReadonly = computed(() => !isInEdit.value);
    const toggleIsInEdit = (value: boolean) => {
      isInEdit.value = value;
      alloverEdit.value = value;
    };
    const beforeClose = () => {
      isInEdit.value = false;
      afterUpdate();
      const data = _.cloneDeep(currentModel.value?.indexList || []);
      indexList.value = data.map((item: any) => ({
        ...item,
        indexType: indexTypeEnum[item.idxType],
        indexFields: changeFields(item.fields),
      }));
    };

    const handleCancel = () => {
      if (isInEdit.value) {
        ElMessageBox.confirm(`编辑中的数据尚未保存，是否退出?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          beforeClose();
        });
      } else {
        beforeClose();
        context.emit('back');
      }
    };

    const initIndexFieldsOption = async (id: any) => {
      const { code, data } = await getIndexFields(id);
      if (code === 0) {
        fields.value = data.map((item: any) => ({
          label: item.columnName,
          value: item.columnName,
        }));
      }
    };
    const handelChange = async () => {
      if (isInEdit.value) {
        const answer = await ElMessageBox.confirm('编辑中的数据尚未保存，请先保存数据！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        if (!answer) return;
        save();
        return false;
      }
      return true;
    };
    watch(currentModel, () => {
      if (currentModel?.value?.external) {
        isInEdit.value = false;
      }
      if (currentModel.value?.id) {
        initIndexFieldsOption(currentModel.value?.id);
      }
    });
    return {
      indexList,
      currentModel,
      fields,
      indexTypeOptions,
      isRefrenceService,
      currentServiceSourceAllowed,
      add,
      remove,
      save,
      isIndexDisabled,
      getShowBool,
      isInEdit,
      isReadonly,
      toggleIsInEdit,
      handleCancel,
      handelChange,
      tableForm,
      formRef,
    };
  },
});
