<template>
  <el-popover :width="400" :visible="visible" placement="top" :disabled="isDisabled">
    <template #reference>
      <div class="data-type__input" :class="isDisabled ? 'is-disabled' : ''" tabindex="1" @click="visible = true">
        <span>{{ typeObj.dbTypeName }}</span>
      </div>
    </template>
    <div class="data-type__container">
      <div class="data-type__header">
        <el-radio-group v-model="type">
          <el-radio :label="DATA_TYPE.PRESET"> 预置类型 </el-radio>
          <el-radio :label="DATA_TYPE.CUSTOM"> 自定义类型 </el-radio>
        </el-radio-group>
      </div>
      <div class="data-type__content">
        <el-form :model="dataType" label-width="60px" ref="formRef" :rules="formRules" :validate-on-rule-change="false">
          <el-form-item label="类型" prop="dbTypeName" v-if="type === DATA_TYPE.PRESET" key="dbTypeName">
            <el-select
              v-model="dataType.dbTypeName"
              :popper-append-to-body="false"
              style="width: 100%"
              @change="(value) => handleChange('dbTypeName', value)"
            >
              <el-option
                v-for="item in presetTypeOptions"
                :key="item.id"
                :value="item.name"
                :label="item.name"
                :disabled="item.id === 1"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="dbType" v-if="type === DATA_TYPE.CUSTOM" key="dbType">
            <el-select
              v-model="dataType.dbType"
              style="width: 100%"
              :popper-append-to-body="false"
              @change="(value) => handleChange('dbType', value)"
            >
              <el-option v-for="item in dbTypes" :key="item.dbType" :value="item.dbType" :label="item.dbType">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="总长"
            v-if="type === DATA_TYPE.CUSTOM && currentDbType.requireLength"
            prop="dbTypeLength"
            key="dbTypeLength"
          >
            <el-input-number
              v-model="dataType.dbTypeLength"
              @change="(value) => handleChange('dbTypeLength', value)"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="精度"
            v-if="type === DATA_TYPE.CUSTOM && currentDbType.requirePrecision"
            prop="dbTypePrecision"
            key="dbTypePrecision"
          >
            <el-input-number
              v-model="dataType.dbTypePrecision"
              @change="(value) => handleChange('dbTypePrecision', value)"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <div class="data-type__footer">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { computed, ref, watch } from 'vue';

export default {
  name: 'DataTypePopover',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    typeObj: {
      type: Object,
    },
    dbTypes: {
      type: Array,
    },
    presetTypeOptions: {
      type: Array,
    },
  },
  emits: ['changeDataType'],
  setup(props, { emit }) {
    const dataType = ref({ ...props.typeObj });
    watch(
      () => props.typeObj,
      (newVal) => {
        dataType.value = { ...newVal };
      },
      { deep: true },
    );

    const visible = ref(false);

    // 预置类型或自定义类型变量
    const DATA_TYPE = {
      PRESET: 0,
      CUSTOM: 1,
    };

    // 预置类型或自定义类型回显逻辑
    const typeLogic = computed(() => {
      if (!dataType.value.dbTypeName) {
        return DATA_TYPE.PRESET;
      }
      return props.presetTypeOptions.find((item) => item.name === dataType.value.dbTypeName)
        ? DATA_TYPE.PRESET
        : DATA_TYPE.CUSTOM;
    });

    const type = ref(typeLogic.value);

    // 获取当前的dbType对象
    const currentDbType = computed(() => {
      if (!dataType.value.dbType) {
        return {};
      }
      return props.dbTypes.find((item) => item.dbType === dataType.value.dbType);
    });

    // 获取当前的预置数据对象
    const currentPresetObj = computed(() => {
      if (!dataType.value.dbTypeName) {
        return {};
      }
      return props.presetTypeOptions.find((item) => item.name === dataType.value.dbTypeName);
    });

    const handleChange = (key, value) => {
      dataType.value[key] = value;
      if (key === 'dbType') {
        dataType.value.dbTypeLength = 0;
        dataType.value.dbTypePrecision = 0;
      }
    };

    const formRef = ref();
    const formRules = computed(() => ({
      dbTypeName: [{ required: true, message: '请选择类型', trigger: 'blur' }],
      dbType: [{ required: true, message: '请选择类型', trigger: 'blur' }],
      dbTypeLength: [
        { required: true, message: '请输入长度', trigger: 'blur' },
        {
          type: 'number',
          min: currentDbType.value.minLength,
          max: currentDbType.value.maxLength,
          message: `长度在${currentDbType.value.minLength}到${currentDbType.value.maxLength}之间`,
          trigger: 'blur',
        },
      ],
      dbTypePrecision: [
        { required: true, message: '请输入精度', trigger: 'blur' },
        {
          type: 'number',
          min: currentDbType.value.minPrecision,
          max: currentDbType.value.maxPrecision,
          message: `精度在${currentDbType.value.minPrecision}到${currentDbType.value.maxPrecision}之间`,
          trigger: 'blur',
        },
      ],
    }));

    const handleClose = () => {
      formRef.value.resetFields();
      visible.value = false;
    };

    // 自定义类型时生成dbTypeName
    const generateDbTypeName = () => {
      if (!currentDbType.value.requireLength) {
        return `${dataType.value.dbType}`;
      }
      if (!currentDbType.value.requirePrecision) {
        return `${dataType.value.dbType} (${dataType.value.dbTypeLength})`;
      }
      return `${dataType.value.dbType} (${dataType.value.dbTypeLength},${dataType.value.dbTypePrecision})`;
    };

    // 构建预制类型的传参字段
    const setPresetTypeDbFields = () => {
      dataType.value.dbType = currentPresetObj.value.dbType;
      dataType.value.dbTypeLength = currentPresetObj.value.length;
      dataType.value.dbTypePrecision = currentPresetObj.value.precision;
      dataType.value.javaType = currentPresetObj.value.type;
    };

    // 构建自定义类型的传参字段
    const setCustomTypeDbFields = () => {
      dataType.value.dbTypeName = generateDbTypeName();
      dataType.value.javaType = currentDbType.value.javaType;
      if (!currentDbType.value.requireLength) {
        dataType.value.dbTypeLength = null;
      }
      if (!currentDbType.value.requirePrecision) {
        dataType.value.dbTypePrecision = null;
      }
    };

    const handleSubmit = () => {
      formRef.value.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (type.value === DATA_TYPE.CUSTOM) {
          setCustomTypeDbFields();
        } else {
          setPresetTypeDbFields();
        }
        emit('changeDataType', { ...dataType.value });
        handleClose();
      });
    };

    return {
      DATA_TYPE,
      type,
      formRef,
      formRules,
      handleChange,
      dataType,
      currentDbType,
      visible,
      handleSubmit,
      handleClose,
    };
  },
};
</script>

<style scoped lang="scss">
.data-type__input {
  border: 1px solid #dcdfe6;
  padding: 0 15px;
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  &:focus-within {
    border-color: #409eff;
  }
  &.is-disabled {
    background-color: var(--el-disabled-fill-base);
    border-color: var(--el-disabled-border-base);
    color: var(--el-disabled-color-base);
    cursor: not-allowed;
  }
}
.data-type__container {
  margin: 20px 10px;
  .data-type__header {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .data-type__footer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
}
</style>
