
import { defineComponent, inject } from '@vue/runtime-core';
export default defineComponent({
  name: 'dependency-dialog',
  setup() {
    const {
      status,
      versions,
      dialogVisible,
      currentDependency,
      severListCanAdd,
      selectDependencies,
      save,
      close,
      changeSelectDependenciesHandler,
    }: any = inject('dependencyOptions');

    const handleFilterServices = (node: any, keyword: string) =>
      node.text.toLocaleLowerCase().includes(keyword.toLocaleLowerCase());

    return {
      status,
      versions,
      dialogVisible,
      currentDependency,
      severListCanAdd,
      selectDependencies,
      save,
      close,
      changeSelectDependenciesHandler,
      handleFilterServices,
    };
  },
});
