
import { defineComponent } from 'vue';
import { relationLines, newRelationLine, tempLinePath, tempRelation } from './store';
export default defineComponent({
  name: 'ErdRelation',
  setup() {
    return {
      relationLines,
      newRelationLine,
      tempLinePath,
      tempRelation,
    };
  },
});
